import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpgradeSubscriptionButton from '../subscription/UpgradeSubscriptionButton';
import { tokens } from '../../theme';

const ProAnalystDialog = ({ open, onClose, uid }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      PaperProps={{
        sx: {
          bgcolor: colors.primary[400],
          color: 'white',
          borderRadius: '20px',
          boxShadow: 'none',
          position: 'relative',
          width: { xs: '75%', sm: '60%', md: '35%' }, // Responsive widths
          overflow: 'visible'
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="dialog-title" sx={{ fontWeight: 'bold', fontSize: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Pro Analyst
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
          Get access to all the premium features we offer!
        </Typography>
        <List sx={{ my: 2 }}>
          {['Positive Expected Value', '1x2 Predictions', 'Double Predictions', 'H2H Statistics', 'Live Results'].map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="success" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
        <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
          Only 3.99€ / month
        </Typography>
        <UpgradeSubscriptionButton uid={uid} />
      </DialogContent>
    </Dialog>
  );
};

export default ProAnalystDialog;
