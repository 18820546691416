import {
  Box,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { doc, setDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setUserId, logEvent } from '@firebase/analytics';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Loader from './components/Loader';
import Footer from './scenes/global/Footer';
import LandingPage from './scenes/landingpage';
// import Dashboard from './scenes/dashboard';
import Sidebar from './scenes/global/NavBar';
import Tips from './scenes/tips';
import PersonalInfo from './scenes/personalinfo';
import About from './scenes/about';
import Fixture from './scenes/fixture';
import SignIn from './scenes/signin';
import SignUp from './scenes/signup';
import Subscription from './scenes/subscription';
import PrivacyPolicy from "./scenes/privacy";
import TermsOfService from "./scenes/tos";
import SubscriptionTerms from "./scenes/subscriptionterms/index.jsx";
import Euro24 from "./scenes/euro24/index.jsx";
import { auth, analytics, db } from './Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import usePremiumStatus from './stripe/usePremiumStatus.ts';

function App() {
  const [theme, colorMode] = useMode();
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  const userIsPremium = usePremiumStatus(user);

  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid);
    }
  }, [user]);

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_location: location.pathname,
      page_title: document.title,
    });

    if (user) {
      const pageViewEvent = {
        userId: user.uid,
        pageLocation: location.pathname,
        pageTitle: document.title,
        timestamp: new Date(), // current time
      };
      const docRef = doc(db, "pageViews", user.uid + "-" + Date.now()); // unique doc ID
      setDoc(docRef, pageViewEvent);
    }
  }, [location, user]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="app">
          <Helmet>
            <meta name="google-adsense-account" content="ca-pub-6549431354822930" />
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6549431354822930" crossorigin="anonymous"></script>
          </Helmet>
          {user && <Sidebar />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
            className="content"
          >
            {loading ? (
              <Loader />
            ) : (
              <Routes>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/"
                  element={
                    user ? (
                      <PrivateRoute user={user}>
                        { userIsPremium ? <Tips isPremium={userIsPremium} /> : <Subscription isPremium={userIsPremium} uid={user?.uid} />}
                      </PrivateRoute>
                    ) : (
                      <LandingPage />
                    )
                  }
                />
                <Route
                  path="/*"
                  element={
                    user ? (
                      <PrivateRoute user={user}>
                        <Tips isPremium={userIsPremium} />
                      </PrivateRoute>
                    ) : (
                      <LandingPage />
                    )
                  }
                />
                <Route
                  path="/tips"
                  element={
                    user ? (
                      <PrivateRoute user={user}>
                        <Tips isPremium={userIsPremium} uid={user?.uid} />
                      </PrivateRoute>
                    ) : (
                      <div>
                        <Tips />
                      </div>
                    )
                  }
                />
                <Route
                  path="/euro24"
                  element={
                    <PrivateRoute user={user}>
                      <Euro24 isPremium={userIsPremium} uid={user?.uid} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <PrivateRoute user={user}>
                      <About />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/personalinfo"
                  element={
                    <PrivateRoute user={user}>
                      <PersonalInfo />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subscription"
                  element={
                    <PrivateRoute user={user}>
                      <Subscription 
                        isPremium={userIsPremium}
                        uid={user?.uid}
                      />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subscriptionterms"
                  element={
                    <PrivateRoute user={user}>
                      <SubscriptionTerms />
                    </PrivateRoute>
                  }
                />
                <Route 
                  path='/fixture/:fixture_id' 
                  element={
                    <PrivateRoute user={user}>
                      {userIsPremium ? 
                        <Fixture /> : 
                        <Subscription uid={user?.uid} />
                      }
                    </PrivateRoute>} 
                />
                <Route
                  path="/privacy"
                  element={
                    <PrivacyPolicy />
                  }
                />
                <Route
                  path="/terms"
                  element={
                    <TermsOfService />
                  }
                />
              </Routes>
            )}
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
