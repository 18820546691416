import React from 'react';
import { Button, Box, styled } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';

const CTA = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Define the primary button style
  const PrimaryButton = styled(Button)({
    padding: '5px 10px',
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '15px',
    color: colors.primary[400],
    backgroundColor: colors.greenAccent[500],
    border: '2px solid',
    borderColor: 'transparent',
    '&:hover': {
      color: colors.grey[100],
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: colors.greenAccent[500],
    },
  });

  // Define the secondary button style
  const SecondaryButton = styled(Button)({
    padding: '5px 10px',
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '15px',
    color: colors.greenAccent[500],
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: colors.greenAccent[500],
      border: '2px solid',
    },
  });

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        gap: '1rem', // Space between buttons
        padding: '1rem 0',
        flexWrap: 'wrap', // Allow buttons to wrap on small screens
      }}
    >
      <PrimaryButton href='/signup'>
        Start now
      </PrimaryButton>
      <SecondaryButton href='https://discord.gg/Q8DqZ2nt'>
        Join our Discord
      </SecondaryButton>
    </Box>
  );
};

export default CTA;
