// ExpectedValue.jsx

import React, { useState } from 'react';
import { Typography, Slider, Paper, Tooltip, Stack, Box } from '@mui/material';

const ExpectedValue = ({ colors }) => {
  const fixedOdds = 2.34;
  const [probability, setProbability] = useState(42);
  const [expectedValue, setExpectedValue] = useState(0);

  const handleProbabilityChange = (event, newValue) => {
    setProbability(newValue);
    updateExpectedValue(fixedOdds, newValue);
  };

  const updateExpectedValue = (odds, probability) => {
    const ev = ((probability / 100 * odds) - 1) * 100;
    setExpectedValue(ev.toFixed(2));
  };

  const isEVPositive = expectedValue >= 0;

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: { xs: '100%', sm: '50%'},
        backgroundColor: colors.primary[400],
        borderRadius: '1rem',
        padding: '20px',
      }}
    >

      {/* Title */}
      <Typography 
        sx={{
          fontSize: { xs: '1.25rem', md: '1.5rem' },
          fontWeight: 'bold',
        }}
      >
        Expected Value Betting
      </Typography>

      {/* Description */}
      <Typography 
        sx={{
          fontSize: { xs: '0.75rem', md: '0.90rem' },
          fontWeight: 'bold',
          color: colors.greenAccent[500],
          marginBottom: '10px'
        }}
      >
         Expected Value (EV) is a key concept in betting, indicating what you can expect to win or lose on average with a bet. 
         It combines the odds of winning with the potential payoff. Simply put, EV is calculated by considering how likely you 
         are to win and what you stand to gain. A positive EV implies a bet is likely profitable over time, while a negative EV 
         suggests it might lead to losses in the long run. Understanding EV helps in making informed betting decisions.
      </Typography>

      {/* Calculator */}
      <Stack
        direction="column"
        justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: colors.primary[500],
            padding: '15px 20px 0px 20px',
            borderRadius: '1rem',
          }}
      >
        <Stack 
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
        >

          {/* Fixed Odds */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: colors.blueAccent[700],
              padding: "8px 12px",
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              fontSize={{ xs: "1rem", md: "1.25rem" }}
              textAlign="center"
            >
              Odd: {fixedOdds}
            </Typography>
          </Paper>

          {/* Probability */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: colors.blueAccent[700],
              padding: "8px 12px",
              marginBottom: 1
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              fontSize={{ xs: "1rem", md: "1.25rem" }}
              textAlign="center"
            >
              Probability: {probability}%
            </Typography>
          </Paper>

          {/* Expected Value */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: isEVPositive ? colors.greenAccent[500] : colors.redAccent[500],
              padding: "8px 12px",
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            <Tooltip title={`Expected Value: ${expectedValue}%`} placement="top">
              <Typography
                variant="h6"
                fontWeight="bold"
                fontSize={{ xs: "1rem", md: "1.25rem" }}
                textAlign="center"
              >
                EV: {expectedValue}%
              </Typography>
            </Tooltip>
          </Paper>
        </Stack>
        
        {/* Slider */}
        <Slider
          aria-label="Probability"
          defaultValue={probability}
          onChange={handleProbabilityChange}
          step={1}
          min={36}
          max={50}
          sx={{
            color: colors.greenAccent[500],
            width: { xs: '100%', sm: '50%' },
            marginTop: '20px',
            marginBottom: '20px',
          }}
        />
      </Stack>
    </Box>
  );
};

export default ExpectedValue;
