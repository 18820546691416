import { Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const HomeDrawAway = ({ HDA, state_id, scores }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const maxProbability = Math.max(HDA.home, HDA.draw, HDA.away);
  const highlightColor = colors.blueAccent[500]; // Choose a highlight color from your theme

  let homeColor = colors.text;
  let drawColor = colors.text;
  let awayColor = colors.text;

  if (state_id === 5) {
    // Determine the actual result
    let actualResult;
    if (scores.home_score > scores.away_score) {
      actualResult = "home";
    } else if (scores.home_score < scores.away_score) {
      actualResult = "away";
    } else {
      actualResult = "draw";
    }

    // Determine colors based on the prediction and actual result
    homeColor = HDA.home === maxProbability
      ? (actualResult === "home" ? colors.greenAccent[500] : colors.redAccent[500])
      : colors.text;
    drawColor = HDA.draw === maxProbability
      ? (actualResult === "draw" ? colors.greenAccent[500] : colors.redAccent[500])
      : colors.text;
    awayColor = HDA.away === maxProbability
      ? (actualResult === "away" ? colors.greenAccent[500] : colors.redAccent[500])
      : colors.text;
  } else {
    homeColor = HDA.home === maxProbability ? highlightColor : colors.text;
    drawColor = HDA.draw === maxProbability ? highlightColor : colors.text;
    awayColor = HDA.away === maxProbability ? highlightColor : colors.text;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      width="100%"
      minHeight="50px"
      padding={{
        xs: 1,
        md: 2,
      }}
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
      marginTop="15px"
      border="1px solid rgba(255, 255, 255, 0.2)"
      bgcolor={colors.primary[400]}
    >
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: HDA.home === maxProbability ? "28px" : "24px",
            color: homeColor,
          }}
        >
          {HDA.home.toFixed(1)}%
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: HDA.draw === maxProbability ? "28px" : "24px",
            color: drawColor,
          }}
        >
          {HDA.draw.toFixed(1)}%
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: HDA.away === maxProbability ? "28px" : "24px",
            color: awayColor,
          }}
        >
          {HDA.away.toFixed(1)}%
        </Typography>
      </Stack>
    </Stack>
  );
};

export default HomeDrawAway;
