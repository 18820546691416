import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { auth } from "../../Firebase";
import { Box, Typography, Tab, Stack, useTheme, Button } from "@mui/material";
import RenderBanner from "./Banner/RenderBanner";
import { tokens } from "../../theme";
import Loader from "../../components/Loader";
import { StyledTabs } from "./utils/Tips.styles";
import { TipsListAccordion } from "./TipsListAccordion/TipsListAccordion";
import { fetchData } from "./utils/fetchData";
import ProAnalystDialog from "../global/ProAnalystDialog";
import { addDays, format } from "date-fns";

const Tips = ({ isPremium, uid }) => {
  const theme = useTheme();
  const colors = useMemo(
    () => tokens(theme.palette.mode),
    [theme.palette.mode]
  );

  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let isMounted = true;

    fetchData().then((data) => {
      if (isMounted) {
        setData(data);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const dates = [
    format(addDays(new Date(), -3), "do MMMM"),
    format(addDays(new Date(), -2), "do MMMM"),
    "Yesterday",
    "Today",
    "Tomorrow",
    format(addDays(new Date(), 2), "do MMMM"),
    format(addDays(new Date(), 3), "do MMMM"),
  ];

  const [selectedDateIndex, setSelectedDateIndex] = useState(3);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // when handling tab change, update both selectedDateIndex and selectedDateId
  const handleDateChange = (event, newValue) => {
    setSelectedDateIndex(newValue);
  };

  const [selectedFilter, setSelectedFilter] = useState("All");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  console.log(data);

  return (
    <Stack direction="column" spacing={2} mb={2}>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "80%", lg: "70%" },
          margin: "auto",
          flexGrow: 1,
        }}
      >
        <Helmet>
          {" "}
          {/* Use Helmet to add a title and meta description */}
          <title>Tips - Eagle Odds</title>
          <meta
            name="description"
            content="Explore upcoming and past matches to find the best betting tips."
          />
        </Helmet>

        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <StyledTabs
            value={selectedDateIndex}
            onChange={handleDateChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {dates.map((date, index) => (
              <Tab key={index} label={date} />
            ))}
          </StyledTabs>
        </Box>
        <RenderBanner
          colors={colors}
          // stats={statsData[dateIds[selectedDateIndex]]}
        />

        {/** Pro Analyst Dialog */}
        {!isPremium ? (
          <ProAnalystDialog
            open={open}
            onClose={() => setOpen(false)}
            uid={uid}
          />
        ) : null}

        <Stack
          direction="row"
          spacing={1}
          mb={2}
          alignItems="center"
          width="100%"
        >
          {selectedDateIndex === 3 && (
            <>
              <Button
                onClick={() => handleFilterChange("All")}
                sx={{
                  backgroundColor:
                    selectedFilter === "All"
                      ? colors.grey[100]
                      : colors.primary[400],
                  color:
                    selectedFilter === "All"
                      ? colors.primary[400]
                      : colors.grey[100],
                  fontWeight: "bold",
                }}
              >
                All
              </Button>
              <Button
                onClick={() => handleFilterChange("Upcoming")}
                sx={{
                  backgroundColor:
                    selectedFilter === "Upcoming"
                      ? colors.grey[100]
                      : colors.primary[400],
                  color:
                    selectedFilter === "Upcoming"
                      ? colors.primary[400]
                      : colors.grey[100],
                  fontWeight: "bold",
                }}
              >
                Upcoming
              </Button>
              <Button
                onClick={() => handleFilterChange("Live")}
                sx={{
                  backgroundColor:
                    selectedFilter === "Live"
                      ? colors.grey[100]
                      : colors.primary[400],
                  color:
                    selectedFilter === "Live"
                      ? colors.primary[400]
                      : colors.grey[100],
                  fontWeight: "bold",
                }}
              >
                Live
              </Button>
            </>
          )}
          {/* <Button
            onClick={() =>
              handleFilterChange(selectedFilter === "By Time" ? "" : "By Time")
            }
            sx={{
              backgroundColor:
                selectedFilter === "By Time"
                  ? colors.grey[100]
                  : colors.primary[400],
              color:
                selectedFilter === "By Time"
                  ? colors.primary[400]
                  : colors.grey[100],
              fontWeight: "bold",
            }}
          >
            By Time
          </Button> */}
        </Stack>

        {selectedFilter === "By Time" ? (
          <TipsListAccordion access={true} league={data[0]} user={user} />
        ) : (
          <>
            {/* Second accordion: All leagues */}
            {isLoading && !data[selectedDateIndex] ? (
              <Stack
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                height="100%"
                minHeight="30vh"
              >
                <Loader />
              </Stack>
            ) : (
              <Stack spacing={0.5} flexGrow={1} height="100%">
                {!!data[selectedDateIndex].leagues?.length ? (
                  data[selectedDateIndex].leagues?.map(
                    (leagueObj, leagueIndex) => (
                      <TipsListAccordion
                        key={leagueIndex}
                        access={isPremium}
                        league={{
                          id: leagueObj.id,
                          name: `${leagueObj.name}`,
                          icon: leagueObj.image_path,
                          fixtures: leagueObj.fixtures,
                        }}
                        user={user}
                      />
                    )
                  )
                ) : (
                  <Stack
                    flexGrow={1}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Typography
                      sx={{
                        color: colors.greenAccent[400],
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      No fixtures for this date.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default Tips;
