import React from "react";
import { Box, Typography } from "@mui/material";

const Stat = ({ label, value, colors }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      p={{ xs: "10px", sm: "15px", md: "20px" }}
      sx={{
        borderRadius: "10px",
        background: colors.primary[400],
        boxShadow: `7px 7px 30px ${colors.primary[900]}, -7px -7px 30px ${colors.primary[400]}`,
      }}
    >
      <Typography
        variant="h4"
        color={colors.grey[200]}
        fontSize={{ xs: ".75rem", sm: "1.2rem", md: "1.5rem" }}
      >
        {`${label}: ${value || "0"}`}
      </Typography>
    </Box>
  );
};

export default Stat;