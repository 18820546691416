import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import fetchTweets from './utils/fetchTweets';

const Tweets = () => {
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    const loadTweets = async () => {
      try {
        const fetchedTweets = await fetchTweets();
        setTweets(fetchedTweets);
      } catch (error) {
        console.error('Error fetching tweets:', error);
      }
    };

    loadTweets();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      const twitterWidgets = document.querySelectorAll('script[src="https://platform.twitter.com/widgets.js"]');
      twitterWidgets.forEach(widget => document.body.removeChild(widget));
    };
  }, []);

  useEffect(() => {
    if (window.twttr && window.twttr.widgets) {
      setTimeout(() => window.twttr.widgets.load(), 10);
    }
  }, [tweets]);

  return (
    <Box sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        maxWidth: "95%", 
        margin: 'auto',
        '& .tweet-container': {
          height: 'auto',
          width: '75%',
        },
        '& .twitter-tweet': {
          margin: 'auto !important',
        },
        mb: '3rem',
      }}
    >
      <Box 
        sx={{
        }}
      >
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: '1rem',
          }}
        >
          Follow us on social media
        </Typography>
      </Box>
      <div className="tweet-container">
        {tweets.map(tweet => (
          <blockquote key={tweet.id} className="twitter-tweet">
            <p lang={tweet.lang} dir={tweet.dir}>{tweet.text}</p>
            &mdash; {tweet.username}
            <a href={`https://twitter.com/${tweet.username.substring(1)}/status/${tweet.id}`}>View Tweet</a>
          </blockquote>
        ))}
      </div>
    </Box>
  );
}

export default Tweets;
