import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, Button } from '@mui/material';
import { tokens } from '../../../theme';

// Smaller component for rendering each probability cell
const ProbabilityCell = ({ value }) => {
  const getColor = (probability) => {
    let opacity = probability / 150;
    return `rgba(0, 255, 0, ${opacity})`;
  };

  return (
    <TableCell align="center" sx={{ borderBottom: 'none', fontWeight: "bold", backgroundColor: getColor(value), fontSize: "12px" }}>
      {value.toFixed(2)}%
    </TableCell>
  );
};

// Smaller component for rendering each team row
const TeamRow = ({ team, isPremium }) => (
  <TableRow sx={{ filter: isPremium || team.name === "England" ? "none" : "blur(10px)" }}>
    <TableCell component="th" scope="row" sx={{ borderBottom: 'none', fontWeight: "bold", fontSize: "12px" }}>
      {team.name}
    </TableCell>
    <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
      <img src={team.logo} alt={team.name} width={20}/>
    </TableCell>
    <ProbabilityCell value={team.R16} />
    <ProbabilityCell value={team.QF} />
    <ProbabilityCell value={team.SF} />
    <ProbabilityCell value={team.F} />
    <ProbabilityCell value={team.W} />
  </TableRow>
);

const EuroHeader = ({ topTeamsData, isPremium }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAll, setShowAll] = useState(true);

  const handleShowAll = () => {
    setShowAll(!showAll); // Toggles the state
  };

  // Sort teams by probability to win (W)
  const sortedTeamsData = [...topTeamsData].sort((a, b) => b.W - a.W);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        width: "100%",
        marginBottom: "15px",
        overflowX: 'auto' // Ensures table is scrollable on small devices
      }}
    >
      <TableContainer 
        component={Box} 
        sx={{ 
          backgroundColor: colors.primary[400],
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", fontSize: "20px"}}></TableCell>
              <TableCell sx={{ fontWeight: "bold", fontSize: "20px"}}></TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px"}}>R16</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px"}}>QF</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px"}}>SF</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px"}}>F</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px"}}>W</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTeamsData.slice(0, showAll ? sortedTeamsData.length : 3).map((team, index) => (
              <TeamRow key={index} team={team} isPremium={isPremium} />
            ))}
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Button variant="contained" onClick={handleShowAll}>{showAll ? "Show Less" : "Show All"}</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EuroHeader;