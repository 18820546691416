// createStripePortalSession.ts
const createStripePortalSession = async (stripeId: string): Promise<void> => {

  console.log('stripeId', stripeId);

    try {
      const response = await fetch('https://europe-north1-ornate-acronym-384208.cloudfunctions.net/createPortalSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ stripeId }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      if (data.session && data.session.url) {
        window.location.href = data.session.url;
      } else {
        throw new Error('Session creation failed');
      }
    } catch (error) {
      console.error('Error creating Stripe portal session:', error);
      // Handle error, e.g., show a message to the user
    }
  };
  
  export default createStripePortalSession;
  