import { db } from "../Firebase";
import { collection, doc, addDoc, onSnapshot } from "firebase/firestore";
import getStripe from "./initStripe.ts";

export async function createCheckoutSession(uid: any, premiumRole: boolean) {

  // Reference to the user's document
  const userDocRef = doc(db, "users", uid);

  console.log("premiumRole", premiumRole);

  // Create a new checkout session in the firestore database
  const checkoutSessionsCollectionRef = collection(userDocRef, "checkout_sessions");
  const checkoutSessionDocRef = await addDoc(checkoutSessionsCollectionRef, {
    price: premiumRole ? "price_1PPC16E0Fa6EOeT032I3Inoe" : "price_1PTLcLE0Fa6EOeT0VEN3RsHy",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    allow_promotion_codes: true,
  });

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(checkoutSessionDocRef, async (snap) => {
    const { sessionId } = snap.data() as { sessionId: string };

    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();

      console.log(stripe);

      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
        console.log("Redirecting to Checkout");
      }
    }
  });
}