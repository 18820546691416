import React from 'react';
import TipsAccordion from './TipsAccordion';

export const TipsListAccordion = ({ access, league, user, defaultExpanded }) => {
  if (!league || !league.fixtures.length) {
    return null;
  }

  return (
    <TipsAccordion
      blurred={!access}
      league={league}
      user={user}
      defaultExpanded={defaultExpanded}
    />
  );
};
