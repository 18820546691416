import { tokens } from "../../theme";
import { Helmet } from "react-helmet";
import { Box, useTheme } from "@mui/material";
import ExpectedValue from "./expectedValue";
import PredictionsGuide from "./predictionsGuide";

const About = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="10px">
      <Helmet>
        {" "}
        {/* Use Helmet to add a title and meta description */}
        <title>About - Eagle Odds</title>
        <meta
          name="description"
          content="About Eagle Odds - How it works."
        />
      </Helmet>

      {/* Expected Value */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ExpectedValue colors={colors}/>
      </Box>

      {/* How we make predicitons */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <PredictionsGuide />
      </Box>
    </Box>
  );
};

export default About;
