import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { motion } from "framer-motion";

const StepBox = ({ component }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <motion.div whileHover={{ scale: 1.1 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: "200px", sm: "250px", md: "350px", lg: "400px" },
          height: { xs: "120px", sm: "140px", md: "180px", lg: "200px" },
          padding: 4,
          backgroundColor: colors.primary[400],
          borderRadius: "10px",
          boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        {component}
      </Box>
    </motion.div>
  );
};

export default StepBox;
