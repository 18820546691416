import { Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { tokens } from '../../../theme';
import TopBar from './TopBar';
import MobileNavMenu from './MobileNavMenu';

const TopSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: { xs: '16px 16px 0', sm: '32px 32px 0', md: '32px 32px 0', lg: '32px 32px 0'},
        backgroundColor: 'transparent',
        margin: 'auto',
        width: { xs: '100%', sm: '90%', md: '80%', lg: '66%' },
        backdropFilter: 'blur(2px)',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <img src={"https://eagleodds.ai/logo_red.png"} alt="eagleodds.ai logo red" style={{ height: '50px' }} />
        <Typography
          sx={{
            flexGrow: 1,
            color: colors.greenAccent[500],
            fontWeight: "bold",
            fontSize: { xs: "1.0rem", md: "1.5rem" },
          }}
        >
          eagleodds.ai
        </Typography>
      </Stack>

      {isLargeScreen ? (
        <TopBar colors={colors} />
      ) : (
        <MobileNavMenu />
      )}

    </Stack>
  );
};

export default TopSection;
