import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

const initStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51N80SrE0Fa6EOeT0uAspG9RuSwFcfDtYa5K5syavvn3qkg2gEE2sZ1XBUxVG4hQeRnWHW1Fk0MOxUaOkwrx6PZos00RAndXMUF"
    );
  }
  return stripePromise;
};

export default initStripe;