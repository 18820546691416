import { Helmet } from 'react-helmet';

const LandingHelmet = () => {
  return (
    <Helmet>
      <title>eagleodds.ai - AI football predicitons</title>
      <meta 
        name="description" 
        content="eagleodds.ai offers expert AI-driven betting tips for football leagues worldwide. Join us for ethical, accurate, and responsible betting guidance. Embrace the future of green betting with eagleodds.ai." 
      />
      <meta 
        name="keywords" 
        content="eagleodds.ai, AI Betting Tips, Football Predictions, Ethical Betting, Green Betting, Premier League, Bundesliga, Ligue 1, Serie A, Responsible Betting" 
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SportsOrganization",
          "name": "eagleodds.ai",
          "description": "eagleodds.ai is your go-to platform for AI-powered, ethical football betting tips. We're pioneering the green betting movement with responsible, data-driven predictions.",
          "url": "https://www.eagleodds.ai",
          "logo": "https://www.eagleodds.ai/logo_red.png",
          "sameAs": [
            "https://twitter.com/EagleOdds",
            "https://www.instagram.com/eagleodds.ai/",
            "https://www.linkedin.com/company/97937772"
          ]
        })}
      </script>
      <meta name="google-adsense-account" content="ca-pub-6549431354822930" />
    </Helmet>
  );
};

export default LandingHelmet;
