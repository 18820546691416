import React, { useEffect } from "react";
import { useTheme, Typography, Box } from "@mui/material";
import StepBox from "../../components/StepBox";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { tokens } from "../../theme";

const HowItWorks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const controls1 = useAnimation();
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 1,
    rootMargin: "5000px 0px 0px 0px",
  });

  const controls2 = useAnimation();
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
    rootMargin: "5000px 0px 0px 0px",
  });

  const controls3 = useAnimation();
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
    rootMargin: "5000px 0px 0px 0px",
  });

  const controls4 = useAnimation();
  const [ref4, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.5,
    rootMargin: "5000px 0px 0px 0px",
  });

  // Separate useEffect for each control
  useEffect(() => {
    let timer1;
    if (inView1) {
      timer1 = setTimeout(() => {
        controls1.start({ opacity: 1, y: 0 });
      }, 200);
    } else {
      timer1 = setTimeout(() => {
        controls1.start({ opacity: 0, y: 100 });
      }, 200);
    }
    return () => clearTimeout(timer1);
  }, [controls1, inView1]);

  useEffect(() => {
    let timer2;
    if (inView2) {
      timer2 = setTimeout(() => {
        controls2.start({ opacity: 1, y: 0 });
      }, 400);
    } else {
      timer2 = setTimeout(() => {
        controls2.start({ opacity: 0, y: 100 });
      }, 400);
    }
    return () => clearTimeout(timer2);
  }, [controls2, inView2]);

  useEffect(() => {
    let timer3;
    if (inView3) {
      timer3 = setTimeout(() => {
        controls3.start({ opacity: 1, y: 0 });
      }, 600);
    } else {
      timer3 = setTimeout(() => {
        controls3.start({ opacity: 0, y: 100 });
      }, 600);
    }
    return () => clearTimeout(timer3);
  }, [controls3, inView3]);

  useEffect(() => {
    let timer4;
    if (inView4) {
      timer4 = setTimeout(() => {
        controls4.start({ opacity: 1, y: 0 });
      }, 800);
    } else {
      timer4 = setTimeout(() => {
        controls4.start({ opacity: 0, y: 100 });
      }, 800);
    }
    return () => clearTimeout(timer4);
  }, [controls4, inView4]);

  return (
    <Box
      id="howitworks"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
        padding: 4,
        backgroundColor: 'transparent',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Typography
        display="flex"
        justifyContent="center"
        sx={{
          color: colors.grey[100],
          fontSize: { xs: "2rem", md: "3rem" },
          fontWeight: "bold",
          borderBottom: "2px solid",
          borderBottomColor: colors.greenAccent[500],
          width: "100%",
        }}
      >
        HOW IT WORKS
      </Typography>

      {/* Step 1 */}
      <motion.div
        ref={ref1}
        initial={{ opacity: 0, y: -100 }}
        animate={controls1}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            marginTop: 3,
            gap: 2,
          }}
        >
          <StepBox
            component={
              <Box
                sx={{
                  "& img": {
                    height: { xs: "110px", md: "150px", lg: "180px" },
                  },
                }}
              >
                <img src="/1.jpg" alt="logo" />
              </Box>
            }
          />
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: colors.grey[200],
                fontSize: { xs: "1rem", md: "2rem" },
                fontWeight: "bold",
              }}
            >
              1. Data processing
            </Typography>
            <Typography
              sx={{
                color: colors.greenAccent[500],
                fontSize: { xs: "0.75rem", md: "1.3rem" },
              }}
            >
              We gather updated data from multiple sources.
            </Typography>
          </Box>
        </Box>
      </motion.div>

      {/* Step 2 */}
      <motion.div
        ref={ref2}
        initial={{ opacity: 0, y: -100 }}
        animate={controls2}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            marginTop: 3,
            gap: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: colors.grey[200],
                fontSize: { xs: "1rem", md: "2rem" },
                fontWeight: "bold",
              }}
            >
              2. AI analysis
            </Typography>
            <Typography
              sx={{
                color: colors.greenAccent[500],
                fontSize: { xs: "0.75rem", md: "1.3rem" },
              }}
            >
              The data is analyzed by our AI models and produces predictions.
            </Typography>
          </Box>
          <StepBox
            component={
              <Box
                sx={{
                  "& img": {
                    height: { xs: "110px", md: "150px", lg: "180px" },
                  },
                }}
              >
                <img src="/2.jpg" alt="logo" />
              </Box>
            }
          />
        </Box>
      </motion.div>

      {/* Step 3 */}
      <motion.div
        ref={ref3}
        initial={{ opacity: 0, y: -100 }}
        animate={controls3}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            marginTop: 3,
            gap: 2,
          }}
        >
          <StepBox
            component={
              <Box
                sx={{
                  "& img": {
                    height: { xs: "110px", md: "150px", lg: "180px" },
                  },
                }}
              >
                <img src="/3.jpg" alt="logo" />
              </Box>
            }
          />
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: colors.grey[200],
                fontSize: { xs: "1rem", md: "2rem" },
                fontWeight: "bold",
              }}
            >
              3. Compare to odds
            </Typography>
            <Typography
              sx={{
                color: colors.greenAccent[500],
                fontSize: { xs: "0.75rem", md: "1.3rem" },
              }}
            >
              The predictions are compared to the odds from the bookmakers.
            </Typography>
          </Box>
        </Box>
      </motion.div>

      {/* Step 4 */}
      <motion.div
        ref={ref4}
        initial={{ opacity: 0, y: -100 }}
        animate={controls4}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            marginTop: 3,
            gap: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: colors.grey[200],
                fontSize: { xs: "1rem", md: "2rem" },
                fontWeight: "bold",
              }}
            >
              4. Use the tips
            </Typography>
            <Typography
              sx={{
                color: colors.greenAccent[500],
                fontSize: { xs: "0.75rem", md: "1.3rem" },
              }}
            >
              Use the tips to make better betting decisions.
            </Typography>
          </Box>
          <StepBox
            component={
              <Box
                sx={{
                  "& img": {
                    height: { xs: "110px", md: "150px", lg: "180px" },
                  },
                }}
              >
                <img src="/4.jpg" alt="logo" />
              </Box>
            }
          />
        </Box>
      </motion.div>
    </Box>
  );
};

export default HowItWorks;
