import { Box, Stack, Typography } from "@mui/material";

const LegendItem = ({ color, description }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Box borderRadius="15%" bgcolor={color} width="20px" height="20px" />
    <Typography fontSize={{ xs: "0.75rem", sm: "1.0rem", md: "1.2rem" }}>
      {description}
    </Typography>
  </Stack>
);

export default LegendItem;
