import React from 'react';
import { Box } from '@mui/material';

const EmbeddedVideo = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <div style={{ position: 'relative', width: '100%', maxWidth: '350px', height: '620px', overflow: 'hidden', background: '#000' }}>
        <iframe 
          src="https://www.veed.io/embed/6cb42b5f-271d-428b-821b-55657758e3af?watermark=0&color=default&sharing=0&title=0&autoplay=1&muted=0&playsinline=1" 
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} 
          frameBorder="0" 
          title="New Video Title" 
          webkitallowfullscreen="true" 
          mozallowfullscreen="true" 
          allowFullScreen 
        ></iframe>
      </div>
    </Box>
  );
};

export default EmbeddedVideo;