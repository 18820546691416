import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import UpdateIcon from '@mui/icons-material/Update';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const PrivacyDetailed = ({ colors }) => {
  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '1rem',
          background: colors.primary[400],
          padding: '1rem',
        }}
      >
        <Typography variant="h6">Detailed Privacy Policy</Typography>
        <List>
          <ListItem>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="Introduction" secondary="This Privacy Policy demonstrates our commitment to the privacy and protection of your personal information. We explain how we collect, use, and safeguard the data we receive from our users." />
          </ListItem>
          
          <ListItem>
            <ListItemIcon><CollectionsBookmarkIcon /></ListItemIcon>
            <ListItemText primary="Data We Collect" secondary="We collect essential user information, including username, email, password, browser history, payment history to offer personalized services and maintain high-quality user experience." />
          </ListItem>

          <ListItem>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary="How We Collect Data" secondary="Your personal data is gathered at various stages, including account registration, service usage, and payment transactions. We use cookies and similar technologies to enhance user experience." />
          </ListItem>

          <ListItem>
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary="Why We Collect Data" secondary="To enhance our services, customize user experience, analyze usage patterns, and understand user base. This helps in improving our offerings and providing services that meet your needs." />
          </ListItem>

          <ListItem>
            <ListItemIcon><SecurityIcon /></ListItemIcon>
            <ListItemText primary="Data Security" secondary="Data stored securely, anonymized using user IDs. Firebase's auth function used for secure storage. We protect against unauthorized access, disclosure, alteration, or destruction." />
          </ListItem>

          <ListItem>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary="User Rights and Control" secondary="Access, modify, or request deletion of personal data by contacting support. Committed to providing users with control over their personal information." />
          </ListItem>

          <ListItem>
            <ListItemIcon><ChildCareIcon /></ListItemIcon>
            <ListItemText primary="Children’s Privacy" secondary="Services for users 18+; no data knowingly collected from children under 18. Immediate steps taken to remove such information from our records." />
          </ListItem>

          <ListItem>
            <ListItemIcon><UpdateIcon /></ListItemIcon>
            <ListItemText primary="Changes to Privacy Policy" secondary="Policy updated periodically to reflect changes in practices or legal requirements. Significant changes communicated through our website or during next interaction." />
          </ListItem>

          <ListItem>
            <ListItemIcon><ContactMailIcon /></ListItemIcon>
            <ListItemText primary="Contact Us" secondary="For inquiries or concerns, contact at support@eagleodds.ai. Committed to addressing your questions and ensuring your privacy is respected." />
          </ListItem>
        </List>
      </Box>
    </>
  );
}

export default PrivacyDetailed;
