import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";

const TipsWon = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [count, setCount] = useState(0);
  const endValue = 525;
  const duration = 3;
  const ref = useRef(null);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number);
  };

  useEffect(() => {
    // Store the current value of the ref in a variable
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let startTimestamp;
          let animationFrameId;

          const startCounting = (timestamp) => {
            if (!startTimestamp) {
              startTimestamp = timestamp;
            }

            const elapsedTime = (timestamp - startTimestamp) / 1000; // Convert to seconds

            if (elapsedTime < duration) {
              const newValue = Math.floor((elapsedTime / duration) * endValue);
              setCount(newValue);
              animationFrameId = requestAnimationFrame(startCounting);
            } else {
              setCount(endValue);
            }
          };

          animationFrameId = requestAnimationFrame(startCounting);

          // Cleanup function now uses the stable reference from within the effect
          return () => {
            cancelAnimationFrame(animationFrameId);
          };
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Cleanup function also uses the stable reference
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [endValue, duration]); // You don't need to include ref in your dependency array here

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Typography variant='h1' sx={{ fontWeight: 'bold' }}>
        {formatNumber(count)}
      </Typography>
      <Typography
        variant='h4'
        sx={{
          mb: '1rem',
          color: colors.greenAccent[500],
        }}
      >
        EXPECTED VALUE PREDICTIONS WON
      </Typography>
    </Box>
  );
};

export default TipsWon;
