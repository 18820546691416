import { Box, Stack, Typography } from '@mui/material';

const TopBar = ({ colors }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        padding: '10px 0',
        backgroundColor: 'transparent',
      }}
    >
      {[
        { text: 'Sign Up', href: '/signup' },
        { text: 'Sign In', href: '/signin' },
        { text: 'About', href: '#howitworks' },
      ].map((item) => (
        <Box
          key={item.text}
          component="a"
          href={item.href}
          sx={{
            color: colors.greenAccent[500],
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              color: colors.greenAccent[600],
            },
            padding: '5px 10px',
          }}
        >
          <Typography
            variant="button"
            sx={{
              fontSize: { xs: '0.8rem', md: '1.25rem' },
              fontWeight: 'bold',
            }}
          >
            {item.text}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default TopBar;
