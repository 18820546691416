import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Box, Typography, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Person, PersonAdd, Info } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { tokens } from '../../../theme';

const menuItems = [
  {
    title: 'Log in',
    to: '/signin',
    icon: <Person/>,
  },
  {
    title: 'Sign up',
    to: '/signup',
    icon: <PersonAdd />,
  },
  {
    title: 'About',
    to: '#howitworks',
    icon: <Info />,
  },
];

const MobileNavMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{ '& .MuiPaper-root': { backgroundColor: colors.primary[400] } }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.title}
            component={Link}
            to={item.to}
            onClick={handleCloseNavMenu}
            sx={{ padding: '10px', '&:hover': { backgroundColor: 'transparent' } }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {item.icon}
              <Typography textAlign="center" sx={{ marginLeft: '10px' }}>
                {item.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MobileNavMenu;
