import { Stack, useTheme, Typography, Box } from "@mui/material";
import { tokens } from "../../../theme";

const getPlayerLastname = (player_name) => {
  const arr = player_name.split(" ");
  return arr[arr.length - 1];
};

const Player = ({ player }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const lastname = getPlayerLastname(player.player_name);

  return (
    <Stack
      direction="column"
      key={player.id}
      borderRadius="5px"
      alignItems="center"
      gap={0.2}
    >
      <Box
        bgcolor={colors.primary[500]}
        borderRadius="5px"
        width={{
          xs: "16px",
          sm: "20px",
        }}
        height={{
          xs: "16px",
          sm: "20px",
        }}
        textAlign="center"
      >
        <Typography
          fontSize={{
            xs: 11,
            sm: 14,
          }}
        >
          {player.jersey_number}
        </Typography>
      </Box>
      <Typography
        bgcolor="black"
        padding={0.2}
        borderRadius={2}
        width="80px"
        textAlign="center"
        fontSize={12}
      >
        {lastname}
      </Typography>
    </Stack>
  );
};

export default Player;
