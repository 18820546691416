import { Stack } from "@mui/material";
import LegendItem from "./LegendItem";
import Stat from "./Stat";

const RenderBanner = ({ colors, stats }) => {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mb: "10px",
      }}
    >
      {/* Banner with stats */}
      {stats?.predicted && (
        <Stack direction="row" spacing={2}>
          <Stat
            label="Predicted matches"
            value={stats?.predicted}
            colors={colors}
          />
          <Stat
            label="Upcoming matches"
            value={stats?.upcoming}
            colors={colors}
          />
          <Stat
            label="Correct predictions"
            value={stats?.correct}
            colors={colors}
          />
        </Stack>
      )}

      {/* Legend */}
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <LegendItem color="green" description="Recommended" />
        <LegendItem color="orange" description="Not optimal" />
        <LegendItem color="red" description="Not recommended" />
      </Stack>
    </Stack>
  );
};

export default RenderBanner;
