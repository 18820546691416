import React, { useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import League from './League';
import Scoreline from './Scoreline';
import Time from './Time';
import OddsAndTips from './OddsAndTips';
import fetchMatchData from './utils/fetchMatch';

const Match = () => {
  const [matchData, setMatchData] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMatchData();
      if (data) {
        setMatchData(data);
      }
    };

    fetchData();
  }, []);

  if (!matchData) {
    return <div>Loading match data...</div>;
  }

  const { league, time, date, home, away, hub } = matchData;

  // Assign default state_id if it's not present
  const scores = {
    state_id: matchData.scores?.state_id || "1",
    home_score: matchData.scores?.home_score || 0,
    away_score: matchData.scores?.away_score || 0,
  };

  // Constructing oddsData from hub data
  const oddsData = [
    { label: "1", data: hub.home },
    { label: "X", data: hub.draw },
    { label: "2", data: hub.away },
  ];

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        width: "100%",
        borderRadius: "10px",
        padding: "15px",
      }}
    >
      {/* League */}
      <League league={league} />

      {/* Countdown */}
      <Time endTime={date + " " + time} />
      
      {/* Scoreline */}
      <Scoreline fixture={{ home, away, scores, time }} />

      {/* Odds and tips */}
      <OddsAndTips oddsData={oddsData} />

    </Box>
  );
};

export default Match;
