import { Box, Typography } from '@mui/material';

const RoundInfo = ({ round }) => {
  return (
    <Box>
      {round && (
        <Typography>
          Round {round.round} {round.name}
        </Typography>
      )}
    </Box>
  );
}

export default RoundInfo;