import { db } from '../../../../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { utcToZonedTime, format } from "date-fns-tz";

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertToLocalTime = (timeString) => {
  const timeZone = getTimeZone();
  const [hour, minute] = timeString.split(":");
  const utcDate = new Date();
  utcDate.setUTCHours(hour, minute, 0, 0);

  const localTime = utcToZonedTime(utcDate, timeZone);
  return format(localTime, "HH:mm", { timeZone });
};

const fetchMatchData = async () => {
  try {
    const nextMatchDocRef = doc(db, 'metaData/nextMatch');
    const nextMatchDoc = await getDoc(nextMatchDocRef);
    if (!nextMatchDoc.exists()) {
      console.error("nextMatch document does not exist");
      return null;
    }

    const nextMatchPath = nextMatchDoc.data().path;
    if (typeof nextMatchPath !== 'string') {
      console.error("Invalid path in nextMatch document");
      return null;
    }

    // Extracting the date from the path
    const pathParts = nextMatchPath.split('/');
    const matchDate = pathParts.length > 1 ? pathParts[1] : null;

    const matchDocRef = doc(db, nextMatchPath);
    const matchDoc = await getDoc(matchDocRef);
    if (!matchDoc.exists()) {
      console.error("Match document does not exist");
      return null;
    }

    const matchData = matchDoc.data();
    if (matchDate) {
      matchData.date = matchDate; // Add the date to the match data
    }

    // Convert time to local time
    matchData.time = convertToLocalTime(matchData.time);

    console.log("matchDoc: ", matchData);
    return matchData;
  } catch (error) {
    console.error("Error fetching match data: ", error);
    return null;
  }
};

export default fetchMatchData;
