import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const TermsOfServiceDetailed = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: { xs: '95%', sm: '80%', md: '60%', lg: '50%' },
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1rem',
        background: colors.primary[400],
        padding: '1rem',
      }}
    >
      <Typography 
        sx={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          mb: '1rem',
        }}
      >
        Terms of Service for <span style={{ color: colors.greenAccent[500] }}>eagleodds.ai</span>
      </Typography>

      <Typography paragraph>
        <strong>1. Introduction:</strong> Welcome to eagleodds.ai, an AI-powered betting tips platform designed to help users make informed decisions. By signing up for our services, you agree to abide by these Terms of Service (TOS).
      </Typography>

      <Typography paragraph>
        <strong>2. Acceptance of Terms:</strong> By using our services, you confirm that you have read, understood, and agree to be bound by these TOS. If you do not agree with these terms, please do not use our services.
      </Typography>

      <Typography paragraph>
        <strong>3. Eligibility:</strong> Our services are intended for individuals who are at least 18 years old. By using our services, you represent and warrant that you are of legal age to form a binding contract.
      </Typography>

      <Typography paragraph>
        <strong>4. User Accounts and Data:</strong> To use our services, you will need to create an account using a valid email address. You are responsible for all activities that occur under your account. We collect your username, email address, and password, and if permitted by you, we track your clicks to enhance our services.
      </Typography>

      <Typography paragraph>
        <strong>5. Subscription and Payment:</strong> Access to our betting tips requires a subscription. The terms of your subscription, including price and duration, will be provided at the time of purchase.
      </Typography>

      <Typography paragraph>
        <strong>6. Use of AI and Tips:</strong> Our platform uses AI to generate betting tips based on calculated expected value. While our AI uses sophisticated algorithms, it should not be considered infallible and you should not rely solely on our tips for your betting decisions. We make no guarantees about the outcome of any bets placed based on our tips.
      </Typography>

      <Typography paragraph>
        <strong>7. Intellectual Property Rights:</strong> The betting tips provided by eagleodds.ai are the intellectual property of eagleodds.ai and may not be shared or disseminated outside of the platform without our express written consent.
      </Typography>

      <Typography paragraph>
        <strong>8. Disclaimer of Warranties and Limitation of Liability:</strong> eagleodds.ai provides AI-powered betting tips to assist in making informed decisions, but we do not guarantee the accuracy of these predictions. You acknowledge that betting involves substantial risk and that you freely and knowingly assume all risk when using our service. eagleodds.ai is not responsible for any losses you may incur as a result of using our services.
      </Typography>

      <Typography paragraph>
        <strong>9. Indemnification:</strong> You agree to indemnify, defend and hold harmless eagleodds.ai, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses relating to or arising out of your use of or inability to use the site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.
      </Typography>

      <Typography paragraph>
        <strong>10. Governing Law and Jurisdiction:</strong> These Terms of Service shall be governed by and construed in accordance with the laws of Norway, without regard to its conflict of law provisions.
      </Typography>

      <Typography paragraph>
        <strong>11. Termination or Cancellation of Account:</strong> You may delete your account at any time. Upon deletion of your account, your user data will be stored anonymously, if permitted by GDPR, otherwise it will be deleted.
      </Typography>

      <Typography paragraph>
        <strong>12. Refund Policy:</strong> If you are not satisfied with our services, you may contact our support for a refund. Refunds are granted at the sole discretion of eagleodds.ai and are not guaranteed.
      </Typography>

      <Typography paragraph>
        <strong>13. Service Changes and Availability:</strong> eagleodds.ai strives to keep the platform available and functional at all times but does not guarantee 100% uptime. We reserve the right to perform maintenance and updates that may cause short-term disruptions.
      </Typography>

      <Typography paragraph>
        <strong>14. User Responsibilities:</strong> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that eagleodds.ai is not responsible for third party access to your account that results from theft or misappropriation of your account. eagleodds.ai and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
      </Typography>

      <Typography paragraph>
        <strong>14. User Responsibilities:</strong> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.
      </Typography>

      <Typography paragraph>
        <strong>15. Prohibited Uses:</strong> You agree not to use the service for any illegal purposes, or to engage in any activities that may harm our service or our users.
      </Typography>

      <Typography paragraph>
        <strong>16. Limitations and Restrictions:</strong> We reserve the right to suspend or terminate your access to our services if we find that you have violated these TOS, including but not limited to, unauthorized sharing of our betting tips.
      </Typography>

      <Typography paragraph>
        <strong>17. Changes to the Terms:</strong> We may modify these TOS at any time. If we make changes, we will notify you by revising the date at the top of these TOS and, in some cases, we may provide you with additional notice.
      </Typography>

      <Typography paragraph>
        <strong>18. Contact:</strong> For any questions or concerns about these TOS, please contact us at support@eagleodds.ai.
      </Typography>

    </Box>
  );
}

export default TermsOfServiceDetailed;