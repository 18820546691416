export const getLiveTime = (periods) => {
    if (!periods || periods.length === 0) {
        return 'Not Started';
    }

    const now = Date.now() / 1000; // current time in seconds
    let matchState = 'Not Started';

    periods.forEach(period => {
        if (period.started && !period.ended) {
            let totalSeconds = Math.floor(now - period.started);
            let matchMinute = Math.floor(totalSeconds / 60) + (period.counts_from || 0);
            let matchSecond = totalSeconds % 60;
            matchMinute = Math.min(matchMinute, period.type_id === 1 ? 45 : 90); // Cap at 45 minutes for first half, 90 for second half
            matchState = `${matchMinute}:${matchSecond.toString().padStart(2, '0')}`;
        } else if (period.ended && !periods.some(p => p.started && !p.ended)) {
            matchState = 'Finished';
        }
    });

    // Handle Half Time separately
    if (periods.length === 2 && periods[0].ended && !periods[1].started) {
        matchState = 'HT';
    }

    return matchState;
};
