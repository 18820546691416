import React from "react";
import { AccordionDetails, Box, Stack } from "@mui/material";
import Fixture from "./Fixture";

const TipsAccordionsDetails = ({ blurred, league, user, theme, colors }) => {
  return (
    <AccordionDetails
      sx={{
        filter: blurred ? "blur(5px)" : "none",
        padding: theme.spacing(2),
        borderTop: "1px solid rgba(0, 0, 0, .125)",
        px: {
          xs: 0,
          sm: 1,
        },
        pt: {
          xs: 0.5,
          sm: 1,
        },
        pb: 1,
      }}
    >
      <Stack direction="column">
        {league?.fixtures?.map((fixture, fixtureIndex) => (
          <Box key={fixtureIndex}>
            <Fixture
              fixture={fixture}
              fixtureIndex={fixtureIndex}
              user={user}
              colors={colors}
            />
          </Box>
        ))}
      </Stack>
    </AccordionDetails>
  );
};

export default TipsAccordionsDetails;
