import React from "react";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { convertToLocalTime } from "../utils/helpers";
import { TeamLogo } from "../utils/Tips.styles";
import { renderBestTip } from "../BestTip/RenderBestTip";

const FixtureInfo = ({
  fixture,
  tips,
  status,
  scores,
  periods,
  fixtureIndex,
  user,
  colors,
  scorelinePrediction,
}) => {
  return (
    <Link
      to={`/fixture/${fixture.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Stack
        sx={{
          backgroundColor:
            fixtureIndex % 2 === 0 ? colors.primary[500] : colors.primary[400],
          "&:hover": {
            backgroundColor: colors.primary[600],
          },
          height: {
            xs: "auto",
            sm: "3rem",
            md: "3rem",
            lg: "3.5rem",
          },
        }}
        direction={{
          xs: "column",
          sm: "row",
        }}
        px={2}
        py={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          flexGrow={1}
          width={{
            xs: "auto",
            sm: "100%",
          }}
          maxWidth={{
            xs: "auto",
            sm: "15%",
          }}
        >
          <Typography
            color={status.id === 1 ? "white" : "green"}
            fontSize={{
              xs: "0.75rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.25rem",
            }}
            fontWeight={{
              xs: "bold",
              sm: "normal",
            }}
            pr={{
              xs: 0,
              sm: 1,
            }}
          >
            {status.id === 1
              ? convertToLocalTime(fixture.starting_at)
              : status.name}
          </Typography>
        </Stack>
        <Stack
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          direction="row"
          width="100%"
        >
          <Stack
            direction="row"
            alignItems="center"
            flexBasis={0}
            flexGrow={1}
            flexShrink={1}
            justifyContent="flex-end"
            maxWidth="40%"
          >
            <Typography
              sx={{
                color: "#EEE",
                fontWeight: "bold",
                mr: 1,
              }}
              textAlign="right"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {fixture.home_team
                ? ` ${fixture.home_team.name} `
                : "Unknown Team"}
            </Typography>
            <TeamLogo
              src={fixture.home_team?.image_path ?? "default-logo-path"}
              alt={fixture.home_team?.name ?? "Unknown Team"}
            />
          </Stack>
          <Stack
            direction="column"
            flexGrow={0}
            justifyContent="center"
            alignItems="center"
            px={1}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: fixture.color,
              }}
              whiteSpace="nowrap"
            >
              {status.id === 1
                ? "vs"
                : `${scores.home_score} - ${scores.away_score}`}
            </Typography>
            {scorelinePrediction && scorelinePrediction.home !== null && (
              <Typography
                sx={{
                  color: "grey",
                  fontSize: "0.55rem",
                  fontWeight: "bold",
                }}
              >
                ({scorelinePrediction.home_team_score.toFixed(0)} -{" "}
                {scorelinePrediction.away_team_score.toFixed(0)})
              </Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            flexBasis={0}
            flexGrow={1}
            flexShrink={1}
            justifyContent="flex-start"
            maxWidth="40%"
          >
            <TeamLogo
              src={fixture.away_team?.image_path ?? "default-logo-path"}
              alt={fixture.away_team?.name ?? "Unknown Team"}
            />
            <Typography
              sx={{
                ml: 1,
                color: "#EEE",
                fontWeight: "bold",
              }}
              textAlign="left"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {fixture.away_team
                ? ` ${fixture.away_team.name}`
                : "Unknown Team"}
            </Typography>
          </Stack>
        </Stack>
        {!user ? (
          <Stack>
            <Link to="/signup">
              <LockOutlinedIcon sx={{ color: "#FFF" }} />
            </Link>
          </Stack>
        ) : (
          <Stack>{renderBestTip(tips, colors)}</Stack>
        )}
      </Stack>
    </Link>
  );
};

export default FixtureInfo;
