import React from 'react';
import { Box } from '@mui/material';
import TermsOfServiceDetailed from './termsOfServiceDetailed';

const TermsOfService = () => {
  return (
    <>
      <Box 
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: '20px',
        }}
      >
        <TermsOfServiceDetailed />
      </Box>
    </>
  );
}

export default TermsOfService;