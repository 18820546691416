import React, { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const canvasStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1 // Ensures the canvas is behind all other content
  };

const ParticleBackground = () => {
  const canvasRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particles = [];
    const maxParticles = 10; // Reduced number of particles

    const createParticle = (ctx) => {
      let startPercent = Math.random() * 5 - 1.5;
      let endPercent = startPercent + (Math.random() * 1 - 0.5); // change by -0.5% to 0.5%
      let duration = 3000 + Math.random() * 2000; // 3000 to 5000 milliseconds
      return {
        x: Math.random() * ctx.canvas.width,
        y: Math.random() * ctx.canvas.height,
        size: Math.random() * 5 + 1,
        vx: Math.random() * 0.1, // Slower movement
        vy: Math.random() * 0.1, // Slower movement
        startPercent,
        endPercent,
        duration,
        progress: 0,
        alpha: 1,
        draw: function() {
          let currentPercent = this.startPercent + (this.endPercent - this.startPercent) * (this.progress / this.duration);
          ctx.fillStyle = currentPercent >= 0 ? colors.greenAccent[500] : colors.redAccent[500];
          ctx.font = 'bold 24px Sans-Serif'; // Bigger and bolder font
          ctx.shadowBlur = 20; // Reduced blur amount for a subtle effect
          ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'; // Shadow color with some transparency
          ctx.globalAlpha = this.alpha; // Apply alpha decay
          ctx.fillText(currentPercent.toFixed(2) + '%', this.x, this.y);
          ctx.globalAlpha = 1; // Reset global alpha
          ctx.shadowBlur = 0; // Reset shadow blur for other drawings
        },        
        update: function(deltaTime) {
          this.progress += deltaTime;
          if (this.progress >= this.duration) {
            this.progress = this.duration;
            this.alpha -= 0.02; // fade out
            if (this.alpha <= 0) {
              // Reset and possibly remove this particle
              this.alpha = 0;
              this.progress = 0; // reset for reuse
              this.startPercent = this.endPercent; // new start is old end
              this.endPercent += Math.random() * 1 - 0.5;
              this.duration = 3000 + Math.random() * 2000;
              if (particles.length > maxParticles) {
                particles = particles.filter(p => p.alpha > 0);
              }
            }
          }
        }
      };
    };

    const addParticles = () => {
        // Add new particles if below maxParticles
        while (particles.length < maxParticles) {
          particles.push(createParticle(ctx));
        }
      };
  
      const removeDeadParticles = () => {
        particles = particles.filter(p => p.alpha > 0);
      };
  
      const animateParticles = (time) => {
        let lastTime = time || 0;
        return function animate(time) {
          let deltaTime = time - lastTime;
          lastTime = time;
  
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          addParticles(); // Add particles if needed
          particles.forEach(p => {
            p.x += p.vx;
            p.y += p.vy;
  
            if (p.x < 0 || p.x > ctx.canvas.width) p.vx *= -1;
            if (p.y < 0 || p.y > ctx.canvas.height) p.vy *= -1;
  
            p.update(deltaTime);
            p.draw();
          });
          removeDeadParticles(); // Remove dead particles
  
          requestAnimationFrame(animate);
        };
      };
  
      addParticles(); // Initial population of particles
      requestAnimationFrame(animateParticles());
  
      return () => particles = []; // Clear particles on unmount
    }, [colors]);
  
    return <canvas ref={canvasRef} style={canvasStyle} width={window.innerWidth} height={window.innerHeight} />;
  };
  
  export default ParticleBackground;
