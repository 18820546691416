import { styled } from '@mui/system';
import { TextField, Select, Button } from '@mui/material';
import { tokens } from '../../theme';

export const CustomTextField = styled(TextField)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  
  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey[300],
      },
      '&:hover fieldset': {
        borderColor: colors.grey[100],
      },
      '&.Mui-focused fieldset': {
        color: colors.grey[200], // Setting the color of the fieldset text when focused
        borderColor: colors.blueAccent[500],
      },
    },
    '& .MuiInputLabel-outlined': {
        color: colors.grey[100],
    },
    '& .MuiInputLabel-outlined.Mui-focused': { // Target the label specifically when input is focused
        color: colors.grey[200],
    },
  };
});







export const CustomSelect = styled(Select)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  
  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey[300],
      },
      '&:hover fieldset': {
        borderColor: colors.grey[100],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.greenAccent[300],
      },
    },
  };
});

export const CustomButton = styled(Button)(({ theme }) => {
  const colors = tokens(theme.palette.mode);
  
  return {
    '&.MuiButton-root': {
      backgroundColor: colors.blueAccent[500],
      '&:hover': {
        backgroundColor: colors.greenAccent[400],
      },
    },
  };
});

export const CustomSignUp = styled(Button)(({ theme, bgcolor }) => {
  const colors = tokens(theme.palette.mode);
  
  return {
    '&.MuiButton-root': {
      backgroundColor: bgcolor,
      '&:hover': {
        backgroundColor: colors.greenAccent[400],
      },
      width: '100%',
      height: '5vh', // Adjust height as necessary
      padding: '0 15px', // Adjust padding as necessary
      borderRadius: '10px',
    },
  };
});

