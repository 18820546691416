import { Box, Typography } from "@mui/material";

const TeamInformation = ({ home_team, away_team, scores }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Adjusted for spacing
        width: '100%',
        gap: '20px',
        marginBottom: '10px',
        paddingBottom: '5px',
      }}
    >
      {/* Home team on the left */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexBasis: '40%', // Set a maximum basis
          justifyContent: 'flex-end',
          gap: '10px',
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }
        }}
      >
        <Typography component="span" className="team-name" fontWeight="bold">
          {home_team.name}
        </Typography>
        <img src={home_team.image_path} alt={home_team.name} width={30} />
      </Box>

      {/* Score in the center */}
      <Box
        sx={{
          textAlign: "center",
          flexBasis: '20%', // Center element basis
        }}
      >
        <Typography
          component="span"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
          }}
        >
          {scores.home_current} - {scores.away_current}
        </Typography>
      </Box>

      {/* Away team on the right */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexBasis: '40%', // Set a maximum basis
          justifyContent: 'flex-start',
          gap: '10px',
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          }
        }}
      >
        <img src={away_team.image_path} alt={away_team.name} width={30}/>
        <Typography component="span" className="team-name" fontWeight="bold">
          {away_team.name}
        </Typography>
      </Box>
    </Box>
  );
}

export default TeamInformation;
