import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children, user }) {
  const location = useLocation();

  useEffect(() => {
    // Save the authentication state to local storage
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  // If the user is not logged in and there is no authentication state in local storage, redirect them to the sign-in page
  if (!user && !localStorage.getItem("user")) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  // If the user is logged in, render the child component
  return children;
}

export default PrivateRoute;
