import { Stack, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import StatLine from "./StatLine";

const Statistics = ({ statistics }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const statisticsArray = Object.entries(statistics).map(([key, value]) => ({
    id: key,
    home: value.home,
    away: value.away,
  }));

  return (
    <Stack
      direction="column"
      color={colors.primary[400]}
    >
      {statisticsArray.map((stat) => (
        <StatLine
          name={stat.id}
          home={stat.home}
          away={stat.away}
        />
      ))}
    </Stack>
  );
}

export default Statistics;