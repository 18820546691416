// MainInfo.jsx
import { useState, useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { getLiveTime } from "./getLiveTime";
import TeamInformation from "./TeamInformation";
import Time from "./Time";
import HomeDrawAway from "./HomeDrawAway";

const MainInfo = ({ mainInfo, starting_at, periods, state_id, scores, HDA }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [liveTime, setLiveTime] = useState(getLiveTime(periods));

  useEffect(() => {
    const interval = setInterval(() => {
      setLiveTime(getLiveTime(periods));
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [periods]);

  return (
    <Stack
      sx={{
        backgroundColor: colors.primary[400],
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        width: "100%",
        borderRadius: "10px",
        padding: "15px",
      }}
    >
      {/* Time */}
      <Time state_id={state_id} liveTime={liveTime} starting_at={starting_at} />

      {/* Team Information */}
      <TeamInformation
        home_team={mainInfo.home_team}
        away_team={mainInfo.away_team}
        scores={scores}
      />

      {/* Home Draw Away */}
      <HomeDrawAway
        HDA={HDA}
        state_id={state_id}
        scores={scores}
      />

    </Stack>
  );
};

export default MainInfo;
