import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import UpdateIcon from '@mui/icons-material/Update';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const PrivacyDetailed = ({ colors }) => {
  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '1rem',
          background: colors.primary[400],
          padding: '1rem',
        }}
      >
        <Typography variant="h6">Privacy Policy Summary</Typography>
        <List>
          <ListItem>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="Introduction" secondary="Outlines commitment to protecting personal information and data handling." />
          </ListItem>
          
          <ListItem>
            <ListItemIcon><CollectionsBookmarkIcon /></ListItemIcon>
            <ListItemText primary="Data We Collect" secondary="Username, email, password, browser history, payment history." />
          </ListItem>

          <ListItem>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary="How We Collect Data" secondary="Information gathered during account creation, service usage, and payment transactions." />
          </ListItem>

          <ListItem>
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary="Why We Collect Data" secondary="To enhance services, analyze usage patterns, and understand user base." />
          </ListItem>

          <ListItem>
            <ListItemIcon><SecurityIcon /></ListItemIcon>
            <ListItemText primary="Data Security" secondary="Data stored securely and anonymized through user IDs. Firebase's auth function used for secure storage." />
          </ListItem>

          <ListItem>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary="User Rights and Control" secondary="Contact support email to modify or delete personal data." />
          </ListItem>

          <ListItem>
            <ListItemIcon><ChildCareIcon /></ListItemIcon>
            <ListItemText primary="Children’s Privacy" secondary="Services for users 18+; no data knowingly collected from children under 18." />
          </ListItem>

          <ListItem>
            <ListItemIcon><UpdateIcon /></ListItemIcon>
            <ListItemText primary="Changes to Privacy Policy" secondary="Policy may be updated periodically; significant changes communicated via website." />
          </ListItem>

          <ListItem>
            <ListItemIcon><ContactMailIcon /></ListItemIcon>
            <ListItemText primary="Contact Us" secondary="For inquiries, contact at support@eagleodds.ai." />
          </ListItem>
        </List>
      </Box>
    </>
  );
}

export default PrivacyDetailed;
