import { Box, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { utcToZonedTime, format, zonedTimeToUtc } from "date-fns-tz";
import { keyframes } from "@emotion/react";

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const pulseRed = keyframes`
  0% {
    background-color: #ff0000;
  }
  50% {
    background-color: #ff4d4d;
  }
  100% {
    background-color: #ff0000;
  }
`;

const Scoreline = ({ match, scores, state_id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Create a Date object using the match starting time
  const timeZone = getTimeZone();
  const utcDate = zonedTimeToUtc(match.starting_at, "UTC");
  const localTime = utcToZonedTime(utcDate, timeZone);
  const timeString = format(localTime, "HH:mm", { timeZone });
  const dateString = format(localTime, "MMM d", { timeZone });

  const scoreBoxAnimation = (state_id !== 1 && state_id !== 5) ? `${pulseRed} 2s infinite` : "none";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
        width: "100%",
      }}
    >
      <Stack direction="column" spacing={0}>
        <img
          src={match.home_team.image_path}
          alt={match.home_team.name}
          width={70}
        />
      </Stack>
      <Stack direction="column" spacing={0} alignItems="center">
        {scores.home_score || scores.away_score ? (
          <Box
            sx={{
              backgroundColor: state_id !== 1 && state_id !== 5 ? "#ff0000" : colors.greenAccent[600],
              paddingLeft: "8px",
              paddingRight: "8px",
              borderRadius: '8px',
              animation: scoreBoxAnimation,
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: "24px" }}
              color={colors.text}
            >
              {scores.home_score} - {scores.away_score}
            </Typography>
          </Box>
        ) : (
          <Typography
            sx={{ fontWeight: "bold", fontSize: "24px" }}
            color={colors.text}
          >
            {timeString}
          </Typography>
        )}
        <Typography
          sx={{ fontWeight: "bold", fontSize: "12px" }}
          color={colors.text}
        >
          {dateString}
        </Typography>
      </Stack>
      <Stack direction="column" spacing={0}>
        <img
          src={match.away_team.image_path}
          alt={match.away_team.name}
          width={70}
        />
      </Stack>
    </Box>
  );
};

export default Scoreline;
