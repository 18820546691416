import { Box } from '@mui/material';
import ParticleBackground from './assets/ParticleAnimation';
import LandingHelmet from './LandingHelmet/LandingHelmet';
import TopSection from './TopSection/TopSection';
import IntroSection from './IntroSection/IntroSection';
import NextMatch from './NextMatch/NextMatch';
import Tweets from './Tweets/Tweets';
import TipsWon from './TipsWon/TipsWon';
import EmbeddedVideo from './euroVideo/EmbeddedVideo';
// import SuccessStories from './SuccessStories/SuccessStories';
// import SubscriptionPlans from './SubscriptionPlans/SubscriptionPlans';
import HowItWorks from '../global/HowItWorks';
import FAQ from '../global/FAQ';

const LandingPage = () => {

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>

      {/* Helmet for SEO */}
      <LandingHelmet />

      {/* Particle background */}
      <ParticleBackground />

      {/* Navbar */}
      <TopSection style={{position: 'sticky', top: '0'}}/>

      {/* Intro section */}
      <IntroSection />

      {/* Euro 2024 video */}
      <EmbeddedVideo />

      {/* Next match section */}
      <NextMatch />

      {/* Tweets section */}
      <Tweets />

      {/* Tips won */}
      <TipsWon />

      {/* Success stories */}
      {/* <SuccessStories /> */}

      {/* Subscription plans */}
      {/* <SubscriptionPlans /> */}

      {/* How it works */}
      <HowItWorks />

      {/* FAQ */}
      <FAQ />

    </Box>
  )
}

export default LandingPage;