import React, { useState, useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import Helmet from 'react-helmet';
import { auth } from '../../Firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {
  Box,
  Grid,
  Link,
  Typography,
  CircularProgress,
  LinearProgress,
  useTheme,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { tokens } from '../../theme';
import { CustomTextField, CustomButton, CustomSignUp } from './SignUp.styles';


function SignUp() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isOver18, setIsOver18] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        
        // The signed-in user info.
        const user = result.user;
  
        // Create a user in your Firestore database
        const db = getFirestore();
        await setDoc(doc(db, "users", user.uid), {
            username: user.displayName,
            role: "Free Plan",
            email: user.email,
            createdAt: new Date(),
        });
  
        // You can add navigation logic here if needed
        navigate('/');
    } catch (error) {
        // Handle Errors here.
        console.error(error);
        setError(error.message);
    }
  };

  useEffect(() => {
    setPasswordStrength(zxcvbn(newPassword).score);
  }, [newPassword]);

  useEffect(() => {
    setPasswordMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  const signUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    const emailValidation = validateEmail(email);
    const passwordStrengthValidation = (passwordStrength >= 3);
    const passwordValidation = validatePassword(newPassword);

    if (!emailValidation.isValid || !passwordValidation.isValid || !passwordStrengthValidation) {
      setError(emailValidation.message || passwordValidation.message || 'Password is not strong enough');
      setLoading(false);
      return;
    }
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, newPassword);
      await updateProfile(userCredential.user, {displayName: username});

      const db = getFirestore();
      await setDoc(doc(db, "users", userCredential.user.uid), {
        username: username,
        role: "Free Plan",
        email: email,
        createdAt: new Date(),
      });

      navigate('/');
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return { 
      isValid: re.test(email), 
      message: re.test(email) ? '' : 'Invalid email format'
    };
  }

  const validatePassword = (password) => {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return { 
      isValid: re.test(password), 
      message: re.test(password) ? '' : 'Password must include at least 8 characters, one upper case, one lower case, and one number'
    };
  }

  const getPasswordStrengthLabel = (strength) => {
    switch(strength) {
      case 0: return '';
      case 1: return 'Very weak';
      case 2: return 'Weak';
      case 3: return 'Good';
      case 4: return 'Strong';
      default: return '';
    }
  };

  const handleCheckboxChange = (event) => {
    setIsOver18(event.target.checked);
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Helmet> {/* Use Helmet to add a title and meta description */}
        <title>Sign Up - Eagle Odds</title>
        <meta name="description" content="Create your Eagle Odds account to get started." />
      </Helmet>
      <Grid padding="15px" maxWidth="xs" component={Box} margin="auto" display="flex" flexDirection="column" alignItems="center">
        <Typography component="h1" variant="h1" fontWeight='bold'>
          Sign up to Eagle Odds
        </Typography>
        {error && <Typography color="error">{error}</Typography>}

        {/* Google Sign Up */}
        <CustomSignUp 
          onClick={signInWithGoogle} 
          variant="contained" 
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#FFF',  // Google brand color
            color: '#000',  // Text color
            borderRadius: '2px',  // Google's buttons are usually more square
            textTransform: 'none',  // Google's buttons don't have capitalized letters
            fontFamily: 'Roboto, sans-serif',  // Google's brand font
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',  // Google-style box-shadow
            padding: '8px 24px',  // Padding as per Google guidelines
            display: 'flex',
            alignItems: 'center'
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/google_logo.png`} alt='Google Logo' style={{ height: '18px', marginRight: '8px' }}/>}  // Adjust the logo size and margin
        >
          Sign up with Google
        </CustomSignUp>
        
        {/* Facebook Sign Up */}
        {/* <CustomSignUp 
          //onClick={signInWithFacebook} 
          variant="contained" 
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#3B5998',  // Facebook brand color
            color: colors.grey[100],  // Text color
            borderRadius: '2px',  
            textTransform: 'none',  
            fontFamily: 'Roboto, sans-serif',  
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)' 
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/facebook_logo.png`} alt='Facebook Logo' style={{ maxHeight: '2.5vh' }}/>}  // Path to the Facebook logo image
        >
          Sign up with Facebook
        </CustomSignUp> */}

        {/* Apple Sign Up */}
        {/* <CustomSignUp 
          //onClick={signInWithApple} 
          variant="contained"
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#000000',  // Apple brand color
            borderRadius: '2px',  
            textTransform: 'none',  
            fontFamily: 'Roboto, sans-serif',  
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)'
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/apple_logo_white.png`} alt='Apple Logo' style={{ maxHeight: '3.5vh' }}/>}  // Path to the Apple logo image
        >
          Sign up with Apple
        </CustomSignUp> */}

        <Typography component="h1" variant="h5" fontWeight='bold' m={1}>
          Or sign up with your email address
        </Typography>
        
        {/* Sign Up Form */}
        <Box component="form" onSubmit={signUp} noValidate sx={{ mt: 1 }}>
          <CustomTextField variant="outlined" margin="normal" required fullWidth id="username" label="Username" name="username" value={username} onChange={e => setUsername(e.target.value)} />
          <CustomTextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" value={email} onChange={e => setEmail(e.target.value)} />
          <CustomTextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password1" autoComplete="current-password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
          <LinearProgress variant="determinate" value={passwordStrength * 25} 
            color={passwordStrength <= 1 ? 'error' : passwordStrength === 2 ? 'warning' : 'success'} 
            sx={{ width: '100%', my: 2 }} 
          />
          <Typography variant="caption">{getPasswordStrengthLabel(passwordStrength)}</Typography>
          <CustomTextField variant="outlined" margin="normal" required fullWidth name="confirm_password" label="Confirm Password" type="password" id="password2" autoComplete="current-password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          {!passwordMatch && <Typography variant="caption" color="error">Passwords do not match</Typography>}


          <Box fontFamily={"Roboto, sans-serif"} fontSize={10} fontWeight="bold" color={colors.grey[100]}>
            {"By signing up, you agree to our"}
            <Link href="/tos.txt" target="_blank" rel="noopener noreferrer" style={{ color: colors.grey[100] }}>
              {"terms and conditions"}
            </Link>
            {" and "}
            <Link href="/privacypolicy.txt" target="_blank" rel="noopener noreferrer" style={{ color: colors.grey[100] }}>
              {"privacy policy"}
            </Link>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={isOver18}
                onChange={handleCheckboxChange}
                name="isOver18"
                color="primary"
              />
            }
            label="I am 18 years old or older"
            labelPlacement="end"
            sx={{ mt: 2 }}
          />

          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || !isOver18}
          >
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </CustomButton>


          <Grid container>
            <Grid item xs>
              <Link href="/signin" variant="body2" color={colors.greenAccent[500]}>
                Already have an account? Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignUp;

