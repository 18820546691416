import { Box, Stack, Typography } from '@mui/material';

const League = ({ league }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        width: '100%',
        marginBottom: { xs: '10px', sm: '10px', md: '10px', lg: '15px'},
        paddingBottom: { xs: '10px', sm: '10px', md: '10px', lg: '15px'},
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <img
          src={league.img_path}
          alt={league.name}
          style={{ width: "20px", height: '20px' }}
        />
        <Typography
          fontWeight="bold"
          fontSize={{ xs: "0.75rem", md: "0.80rem", lg: "1rem" }}
        >
          {league.name}
        </Typography>
      </Stack>
    </Box>
  );
}

export default League;