import { Stack, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Player from "./Player";

const getPosition = (formation_field) => {
  const [x, y] = formation_field.split(":").map((n) => +n);

  return {
    x: x,
    y: y,
  };
};

const generateLineupStructure = ({ main_info, lineups }) => {
  let home = [];
  let away = [];

  lineups.forEach((lineup) => {
    if (lineup.team_id === main_info.home_team.id) {
      if (lineup.formation_field === null) {
        // bench
        return;
      }
      const position = getPosition(lineup.formation_field);
      home[position.x] = home[position.x] || [];
      home[position.x][position.y] = lineup;
    }
    if (lineup.team_id === main_info.away_team.id) {
      if (lineup.formation_field === null) {
        // bench
        return;
      }
      const position = getPosition(lineup.formation_field);
      away[position.x] = away[position.x] || [];
      away[position.x][position.y] = lineup;
    }
  });

  return [home, away];
};

const Lineups = ({ main_info, lineups }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [home, away] = generateLineupStructure({ main_info, lineups });

  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      gap={2}
      minHeight="650px"
    >
      <Stack
        position="relative"
        width="100%"
        alignItems="center"
        gap={2}
        minHeight="600px"
        maxHeight="600px"
        maxWidth="400px"
      >
        <img
          style={{
            maxHeight: "600px",
          }}
          src="/Football_field.svg"
          alt="Football field"
          height="100%"
          width="100%"
        />
        <Stack
          direction="column"
          width="100%"
          position="absolute"
          top="20px"
          left="0"
          height="45%"
          justifyContent="space-evenly"
        >
          {home.map((line) => (
            <Stack direction="row" width="100%" justifyContent="space-evenly">
              {line.map((player) => (
                <Player key={player.id} player={player} />
              ))}
            </Stack>
          ))}
        </Stack>
        <Stack
          direction="column"
          width="100%"
          position="absolute"
          bottom="10px"
          left="0"
          height="45%"
          justifyContent="space-evenly"
        >
          {away.reverse().map((line) => (
            <Stack direction="row" width="100%" justifyContent="space-evenly">
              {line.map((player) => (
                <Player key={player.id} player={player} color={colors.greenAccent[500]} />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Lineups;
