import React, { useEffect, useState } from 'react';
import { db, auth } from '../../Firebase';
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import { Box, Typography, useTheme, TextField, Button, LinearProgress, Tabs, Tab, Switch } from "@mui/material";
import Header from "../../components/Header";
import { getDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { onAuthStateChanged, deleteUser, updatePassword } from 'firebase/auth';
import zxcvbn from 'zxcvbn';

const Settings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({ name: '', email: '', password: '', marketing: false, dataCollection: false });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
              const userDoc = await getDoc(doc(db, 'users', user.uid));
              if (userDoc.exists()) {
                  setUserInfo(userDoc.data());
              }
          }
      });
      return unsubscribe;
  }, []);

  const handleUpdate = async () => {
      const user = auth.currentUser;
      if (user) {
          await updateDoc(doc(db, 'users', user.uid), userInfo);
          alert('Updated successfully');
          navigate('/');
      }
  };

  useEffect(() => {
    setPasswordStrength(zxcvbn(newPassword).score);
  }, [newPassword]);

  const handlePasswordChange = async () => {
    const user = auth.currentUser;
    if (user && newPassword.length >= 8 && newPassword === confirmPassword) {
        await updatePassword(user, newPassword);
        alert('Password updated successfully');
        setNewPassword('');
        setConfirmPassword('');
    } else {
        alert('Password should be at least 8 characters long and match the confirmation password');
    }
  };
  
  const getPasswordStrengthLabel = (score) => {
    switch(score) {
      case 0: return '';
      case 1: return 'Very weak';
      case 2: return 'Weak';
      case 3: return 'Good';
      case 4: return 'Strong';
      default: return '';
    }
};

  const handleSave = async () => {
      const user = auth.currentUser;
      if (user) {
          await updateDoc(doc(db, 'users', user.uid), { marketing: userInfo.marketing, dataCollection: userInfo.dataCollection });
      }
  };

  const handleDeleteAccount = async () => {
      const user = auth.currentUser;
      if (user) {
          await deleteDoc(doc(db, 'users', user.uid));
          await deleteUser(user);
          navigate('/');
      }
  };

  const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
  };

    return (
        <Box m="20px" maxWidth='100vw'>
            <Header title='USER SETTINGS' subtitle='Settings for your eagleodds.ai account' />
            <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label="settings tabs" 
                variant="fullWidth"
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: colors.greenAccent[500],
                    },
                    '& .MuiTab-root': {
                        color: colors.grey[100],
                        fontWeight: 700,
                        fontSize: '1rem',
                    },
                    '& .MuiTab-textColorPrimary.Mui-selected': {
                        color: colors.grey[100],
                    },
                }}
            >  
                <Tab label="Personal Info" />
                <Tab label="Password" />
                {/* <Tab label="Preferences" /> */}
            </Tabs>
            <Box
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center" 
              width="100%" // takes up full width of the parent container
              sx={{ maxWidth: '100vw', boxSizing: 'border-box' }}
            >

              {/* Personal Info */}
              {tabValue === 0 && (
                  <Box
                    mt='5vh'
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    justifyContent="center"
                    width={{ sx: '100%', sm: '50%', md: '30%', lg: '30%'}}
                    sx={{
                      bgcolor: colors.primary[400],
                      padding: '15px',
                      borderRadius: '15px',
                      boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                      <TextField margin="normal" fullWidth label="Username" value={userInfo.name} onChange={e => setUserInfo({ ...userInfo, name: e.target.value })} />
                      <TextField margin="normal" fullWidth label="Email" value={userInfo.email} onChange={e => setUserInfo({ ...userInfo, email: e.target.value })} />

                       {/* Data Collecting and Marketing Switches */}
                      <Box mt={4} display="flex" alignItems="center">
                        <Switch
                          checked={userInfo.dataCollection}
                          onChange={e => setUserInfo({ ...userInfo, dataCollection: e.target.checked })}
                          color="primary"
                        />
                        <Typography variant="body1" color={colors.grey[100]}>
                          Data Collecting
                        </Typography>
                      </Box>

                      <Box mt={2} display="flex" alignItems="center">
                        <Switch
                          checked={userInfo.marketing}
                          onChange={e => setUserInfo({ ...userInfo, marketing: e.target.checked })}
                          color="primary"
                        />
                        <Typography variant="body1" color={colors.grey[100]}>
                          Marketing
                        </Typography>
                      </Box>

                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleUpdate} 
                        sx={{
                          mt: '2.5vh',
                          backgroundColor: colors.blueAccent[600],
                          }}
                        >
                          Update
                        </Button>

                      {/* Delete Account */}
                      <Box mt={20}>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={handleDeleteAccount}
                              sx={{ backgroundColor: colors.redAccent[600] }}
                          >
                            Delete Account
                          </Button>
                      </Box>
                  </Box>
              )}

              {/* Password Change */}
              {tabValue === 1 && (
                  <Box 
                    mt='5vh'
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    justifyContent="center"
                    width={{ sx: '100%', sm: '50%', md: '30%', lg: '30%'}}
                    sx={{
                      bgcolor: colors.primary[400],
                      padding: '15px',
                      borderRadius: '15px',
                      boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                      <TextField 
                        margin="normal" 
                        fullWidth 
                        type="password" 
                        label="New Password" 
                        value={newPassword} 
                        onChange={e => setNewPassword(e.target.value)} 
                      />
                      <LinearProgress variant="determinate" value={passwordStrength * 25} 
                        color={passwordStrength <= 1 ? 'error' : passwordStrength === 2 ? 'warning' : 'success'} 
                        sx={{ width: '100%', my: 2 }} 
                      />
                      <Typography variant="caption">{getPasswordStrengthLabel(passwordStrength)}</Typography>
                      <TextField 
                        margin="normal" 
                        fullWidth 
                        type="password" 
                        label="Confirm New Password" 
                        value={confirmPassword} 
                        onChange={e => {
                          const { value } = e.target;
                          setConfirmPassword(value);
                          setPasswordMatch(newPassword === value);
                        }}
                        />
                      {!passwordMatch && <Typography variant="caption" color="error">Passwords do not match</Typography>}
                      <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={handlePasswordChange}
                          sx={{
                              mt: 2,
                              backgroundColor: colors.greenAccent[600]
                          }}
                          disabled={passwordStrength < 3 || !passwordMatch}
                      >
                          Change Password
                      </Button>
                  </Box>
              )}

              {/* Preferences */}
              {tabValue === 2 && (
                  <Box 
                    mt='5vh'
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    justifyContent="center"
                    width={{ sx: '100%', sm: '50%', md: '30%', lg: '30%'}}
                    sx={{
                      bgcolor: colors.primary[400],
                      padding: '15px',
                      borderRadius: '15px',
                      boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                      {/* Add your league selection UI here */}
                      <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                  </Box>
              )}
            </Box>
        </Box>
    );
};

export default Settings;
