import { Stack, Tab, Tabs, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useMemo, useState } from "react";
import MatchCard from "./matchCard";

const Matches = ({ matches, isPremium }) => {
  const [selectRound, setSelectRound] = useState("1");

  const selectedMatches = useMemo(() => {
    return matches.filter((match) => {
      if (selectRound === "Knockout") {
        return !match.round.round;
      }
      return match.round.round === selectRound;
    });
  }, [matches, selectRound]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const uniqueRounds = ["1", "2", "3", "Knockout"];

  return (
    <Stack
      direction="column"
      alignItems="center"
      width="100%"
      minHeight="150px"
      borderRadius="10px"
      padding={{
        xs: 1,
        md: 2,
      }}
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
      marginTop="15px"
      border="1px solid rgba(255, 255, 255, 0.2)"
      bgcolor={colors.primary[400]}
    >
      <Tabs
        value={selectRound}
        onChange={(event, newValue) => setSelectRound(newValue)}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          width: "100%",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        {uniqueRounds.map((round) => (
          <Tab key={round} label={round} value={round} />
        ))}
      </Tabs>
      <Stack width="100%" direction="column" alignItems="center">
        {selectedMatches.map((match) => (
          <MatchCard match={match} isPremium={isPremium} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Matches;
