// ManageSubscriptionButton.jsx
import React from 'react';
import { Box, Button } from '@mui/material';
import createStripePortalSession from './createStripePortalSession.ts';

const ManageSubscriptionButton = ({ stripeCustomerId }) => {
  console.log('stripeCustomerId', stripeCustomerId);
  const handleManageSubscription = () => {
    createStripePortalSession(stripeCustomerId);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button variant="contained" color="primary" onClick={handleManageSubscription}>
        Manage Subscription
      </Button>
    </Box>
  );
};

export default ManageSubscriptionButton;