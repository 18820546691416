import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import Analyst from './Analyst';
import ManageSubscriptionButton from './ManageSubscriptionButton';
import UpgradeSubscriptionButton from './UpgradeSubscriptionButton';
import { Box, Container, CircularProgress, Stack, useMediaQuery, useTheme } from '@mui/material';
import ProAnalyst from './ProAnalyst';

const Subscription = ({ uid, isPremium }) => {
  const [loading, setLoading] = useState(true);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const checkUserStripeId = async () => {
      if (!uid) {
        console.error('No UID provided');
        setLoading(false);
        return;
      }

      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const { stripeId } = userSnap.data();
        setStripeCustomerId(stripeId);
        setLoading(false);
      } else {
        console.error('User document does not exist');
        setLoading(false);
      }
    };

    checkUserStripeId();
  }, [uid]);

  if (loading) {
    return (
      <Container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 7.5,
        mt: 10,
      }}
    >
      <Stack 
        direction={isMobile ? 'column' : 'row'} 
        justifyContent="center" 
        gap={2.5}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Box 
          sx={{ 
            width: { xs: '100%', sm: '80%', md: '70%', lg: '60%' }, 
            display: 'flex', 
            justifyContent: 'center' 
          }}
        >
          <ProAnalyst 
            actionButton={isPremium ? (
              <ManageSubscriptionButton stripeCustomerId={stripeCustomerId} />
            ) : (
              <UpgradeSubscriptionButton uid={uid} premiumRole={true} />
            )}
          />
        </Box>
        <Box 
          sx={{ 
            width: { xs: '100%', sm: '80%', md: '70%', lg: '60%' }, 
            display: 'flex', 
            justifyContent: 'center' 
          }}
        >
          <Analyst
            actionButton={isPremium ? (
              <ManageSubscriptionButton stripeCustomerId={stripeCustomerId} />
            ) : (
              <UpgradeSubscriptionButton uid={uid} premiumRole={false} />
            )}
          />
        </Box>
      </Stack>
      <Box sx={{ height: '50px' }} />
    </Box>
  );
};

export default Subscription;