import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Chip, Stack, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';
import { tokens } from '../../theme';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ProAnalyst = ({ actionButton }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const imageUrls = [
    'https://cdn.sportmonks.com/images/soccer/leagues/14/1326.png',
    'https://img.uefa.com/imgml/uefacom/ucl/2021/logos/logo_dark.svg',
    'https://cdn.sportmonks.com/images/soccer/leagues/8/8.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/20/564.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/82.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/13/301.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/0/384.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/28/444.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/29/573.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/271.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/9/9.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/24/24.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/27/27.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/20/244.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/181.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/27/1371.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/16/208.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/6/390.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/3/387.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/72.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/5/453.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/14/462.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/501.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/26/570.png',
    'https://cdn.sportmonks.com/images//soccer/leagues/23/567.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/15/591.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/24/600.png',
    'https://cdn.sportmonks.com/images/soccer/leagues/1/609.png'
  ];

  const [isExpanded, setIsExpanded] = useState(false);
  const [imageError, setImageError] = useState(Array(imageUrls.length).fill(false));

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleImageError = (index) => {
    setImageError(prev => {
      const newErrors = [...prev];
      newErrors[index] = true;
      return newErrors;
    });
  };

  return (
    <Card sx={{
      width: { xs: '90%', sm: '80%', md: '70%', lg: '40%' },
      bgcolor: colors.primary[400],
      color: 'white',
      borderRadius: '20px',
      position: 'relative',
      overflow: 'visible',
      boxShadow: 'none'
    }}>
      <Chip 
          label="Most Valuable!" 
          sx={{
              position: 'absolute',
              top: '-20px',
              right: '20px',
              borderRadius: '20px',
              bgcolor: colors.greenAccent[500],
              color: 'white',
              fontWeight: 'bold',
              animation: `${pulsate} 1s infinite`
          }} 
          />
      <CardContent>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '2px solid',
            borderColor: colors.primary[500],
            pb: 0
          }}
        >
          <Box component="img" src="https://eagleodds.ai/logo_red.png" alt="EagleOdds Logo" sx={{ height: '40px' }} />
          <Typography gutterBottom sx={{
            fontWeight: 'bold',
            fontSize: '30px',
            mt: 1
          }}>
            Pro Analyst
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2, mt: 2 }}>
          <Box component="img" src="https://cdn.sportmonks.com/images/soccer/leagues/14/1326.png" alt="EURO icon" sx={{ height: '30px' }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            EURO 2024 included!
          </Typography>
        </Box>
        <Box sx={{ borderBottom: '2px solid', borderColor: colors.primary[500], my: 2 }} />
        <Typography 
          sx={{
            fontSize: '18px', 
            fontWeight: 'bold',
            textAlign: 'center',
            mt: 2,
            mb: 2
          }}
        >
          Get complete access to all the Pro features eagleodds.ai offer!
        </Typography>
        <List sx={{ my: 2 }}>
          {['Positive Expected Value', '1x2 Predictions', 'Double Predictions', 'Over/Under Predictions', 'BTTS Predictions', 'H2H Statistics', 'Live Results'].map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="success" />
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Typography sx={{ fontSize: '1.05rem' }}>
                    {feature}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>

        {/* Image list starts here */}
        <Typography sx={{ fontSize: '12px', textStyle: "italic", marginBottom: 2}}>
          All Leagues included:
        </Typography>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1, 
            cursor: 'pointer' 
          }} 
          onClick={handleExpandClick}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              gap: 1, 
              flexWrap: isExpanded ? 'wrap' : 'nowrap', 
              overflow: isExpanded ? 'visible' : 'hidden',
              height: isExpanded ? 'auto' : '20px'
            }}
          >
            {imageUrls.map((url, index) => (
              <Box 
                key={index} 
                component="img" 
                src={url} 
                alt={`icon-${index}`} 
                sx={{ borderRadius: '2px', height: '20px', width: '20px' }} 
                onError={() => handleImageError(index)} 
              />
            ))}
            {imageError.map((error, index) => (
              error && <Typography key={`error-${index}`} sx={{ fontSize: '16px', fontWeight: 'bold' }}></Typography>
            ))}
          </Box>
          {!isExpanded && <Typography></Typography>}
        </Box>
        {/* Image list ends here */}
        <Box sx={{ borderBottom: '2px solid', borderColor: colors.primary[500], my: 2 }} />

        <Typography 
          sx={{
            fontSize: '18px', 
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 2
          }}
        >
          9.99€ / month
        </Typography>
          {actionButton}
      </CardContent>
    </Card>
  );
};

export default ProAnalyst;