import { Box, Typography, Paper, Tooltip, Stack } from '@mui/material';
import NAComponent from '../../tips/BestTip/NAComponent';

const RenderOdds = ({ oddsData, w }) => {

  const renderOdds = (data, outcome) => {
    const strength = data.expected_value ? (data.expected_value - 1) * 100 : ((data.probability * data.dp3) - 1) * 100;
    const isStrengthPositive = strength >= 0;

    if (typeof data.dp3 === 'undefined' || data.dp3 === null) {
      return <NAComponent />;
    } else {
      return (
        <Box sx={{
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "flex-start", 
          alignItems: "center", 
          width: "100%", 
          height: "100%",
          marginTop: '-5px',
          marginBottom: '10px',
        }}>
          <Box sx={{
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
            flexGrow: 0.1, 
          }}>
            <Typography 
              variant="h4" 
              fontWeight='bold'
              fontSize='1.5vh'
              sx={{ 
                maxWidth: '100%', 
                minWidth: '100%', 
                textAlign: 'center',
                color: "#fff", // replace with your color token
              }}
            >
              {`${outcome.toUpperCase()}`}
            </Typography>
            <Stack direction="column" spacing={1}>
              <Paper elevation={3} sx={{ backgroundColor: "#dbdbdb"}}>
                <Tooltip title={`The odd is ${(data.dp3).toFixed(2)}`} placement="top">
                  <Typography variant="h6" fontWeight='bold' fontSize={{ xs: '0.75rem', md: '1rem' }} sx={{ padding: '1px 4px' }} color="black" textAlign="center">
                    AI: {(data.probability*100).toFixed(0)}%
                  </Typography>
                </Tooltip>
              </Paper>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <Paper elevation={3} sx={{ marginRight: '5px', backgroundColor: "blue"}}> 
                <Tooltip title={`The odd is ${(data.dp3).toFixed(2)}`} placement="top">
                  <Typography variant="h6" fontWeight='bold' fontSize={{ xs: '0.75rem', md: '1rem' }} sx={{ padding: '1px 4px' }}>
                    {(data.dp3).toFixed(2)}
                  </Typography>
                </Tooltip>
              </Paper>
              <Paper elevation={3} sx={{ backgroundColor: data.dp3 >= 9.01 ? 'orange' : (isStrengthPositive ? 'green' : 'red') }}>
                <Tooltip title={`The probability value is ${(strength).toFixed(0)}%`} placement="top">
                  <Typography variant="h6" fontWeight='bold' fontSize={{ xs: '0.75rem', md: '1rem' }} sx={{ padding: '1px 4px' }}>
                    {(strength).toFixed(0)}%
                  </Typography>
                </Tooltip>
              </Paper>
            </Box>
            </Stack>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width={`${w}%`}
        padding="10px"
    >
      {oddsData.map((item) => (
        <div key={item.label}>
          {renderOdds(item.data, item.label)}
        </div>
      ))}
    </Box>
  );
};

export default RenderOdds;