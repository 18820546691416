import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { tokens } from '../../theme';

export const CustomSignIn = styled(Button)(({ theme, bgcolor }) => {
    const colors = tokens(theme.palette.mode);
    
    return {
      '&.MuiButton-root': {
        backgroundColor: bgcolor,
        '&:hover': {
          backgroundColor: colors.greenAccent[400],
        },
        width: '100%',
        height: '5vh', // Adjust height as necessary
        padding: '0 15px', // Adjust padding as necessary
        borderRadius: '10px',
      },
    };
  });