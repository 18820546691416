import { styled } from "@mui/system";
import { Grid, Tabs, Typography } from "@mui/material";

export const TeamLogo = styled("img")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("xs")]: {
    maxWidth: "1.75vh",
    margin: "2px",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "2.5vh",
    margin: "5px",
  },
}));

export const FixtureTypography = styled(Typography)(({ theme }) => ({
  color: "#ddd",
  [theme.breakpoints.up("xs")]: {
    fontWeight: "bold",
    fontSize: "0.70rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontWeight: 600,
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
}));

export const FlexContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
});

export const StyledTabs = styled(Tabs)({
  ".MuiTabs-indicator": {
    backgroundColor: "white",
  },
  ".MuiTab-wrapper": {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  ".MuiTab-root": {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    "&.Mui-selected": {
      color: "#4A90E2",
    },
  },
});
