const BASE_URL = 'https://eagleodds-api-p4nwj3fy4q-lz.a.run.app/api/v1';

export const fetchData = async () => {
    try {
        const response = await fetch(`${BASE_URL}/tips/all-tips`);
        const data = await response.json();
        return data.fixtures;
    } catch (error) {
        console.error('Error fetching data', error);
    }
}