import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const StyledAccordion = ({ title, text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Accordion
      sx={{
        width: "80%",
        margin: "0.5rem 0",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: colors.grey[200] }} />}
        sx={{
          backgroundColor: colors.primary[400],
        }}
      >
        <Typography color={colors.grey[200]} fontWeight="bold">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: colors.primary[400],
        }}
      >
        <Typography color={colors.greenAccent[500]}>{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      id="faq"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: 'transparent',
        width: "100%",
        padding: "2rem 0",
        backdropFilter: 'blur(5px)',
      }}
    >
      {/* FAQ Header */}
      <Typography
        variant="h3"
        color={colors.grey[200]}
        fontWeight="bold"
        sx={{
          margin: "1rem 0",
        }}
      >
        Frequently Asked Questions
      </Typography>

      {/* Question 1 */}
      <StyledAccordion
        title="What is Expected Value (EV) in Betting?"
        text="Expected Value (EV) is a statistical measure used in betting to determine the long-term profitability of a particular wager. It's calculated by multiplying the probability of each outcome by the potential gain or loss from that outcome, then summing these values."
      />

      {/* Question 2 */}
      <StyledAccordion
        title="Can I Trust the AI Predictions?"
        text="While no prediction can guarantee 100% accuracy, our AI model is designed to maximize return on investment over the long run. It's important to consistently follow our AI-based tips for the best results."
      />

      {/* Question 3 */}
      <StyledAccordion
        title="How Can I Get the Most Out of eagleodds.ai?"
        text="To maximize your success, consistently follow our AI-based predictions, manage your bankroll wisely, and make use of the performance graph to track long-term trends."
      />

      {/* Question 4 */}
      <StyledAccordion
        title="What Leagues Do You Cover?"
        text="For now we cover the top European football leagues, but we're working on adding more leagues and sports in the near future."
      />
    </Box>
  );
};

export default FAQ;
