import { Typography, Box, useTheme, Stack } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="30px"
    >
      <Stack direction="column" spacing={1}>
        <Typography
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{
            mb: "5px",
            fontSize: { xs: "1.25rem", md: "2rem" },
          }}
        >
          {title}
        </Typography>
        <Typography
          color={colors.greenAccent[400]}
          sx={{
            fontSize: { xs: "1rem", md: "1.25rem" },
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Header;
