import { Box, Typography } from '@mui/material';
import Match from './Match';

const NextMatch = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '16px',
        width: '80%',
        maxWidth: { xs: '400px', sm: '400px', md: '500px', lg: '600px'},
        margin: 'auto',
        marginBottom: '5rem',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          textAlign: 'center',
          mb: '1rem',
        }}
      >
        NEXT PREDICTION
      </Typography>
      <Match />
    </Box>
  )
}

export default NextMatch;