import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const CountdownTimer = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  console.log("CountdownTimer.jsx: endTime", endTime);

  useEffect(() => {
    // Calculate the remaining time and update state every second
    const interval = setInterval(() => {
      const now = new Date();
      const end = new Date(endTime);

      // Ensure that the provided endTime is valid and in the future
      if (isNaN(end.getTime()) || end <= now) {
        clearInterval(interval);
        console.error("Invalid endTime provided to CountdownTimer");
        console.error(endTime)
        return;
      }

      const difference = end.getTime() - now.getTime();
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // Format the remaining time
      setTimeLeft(`${days} d ${hours} h ${minutes} m ${seconds} s`);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [endTime]);

  return (
    <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={colors.blueAccent[900]} 
      borderRadius={2} 
      pr={1} 
      pl={1}
    >
      <Typography
        variant="h5"
        fontWeight="600"
        fontSize={{ xs: "0.65rem", md: "0.65rem", lg: "0.75rem" }}
        color={colors.greenAccent[500]}
      >
        {timeLeft}
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
