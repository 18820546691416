import { Stack, useTheme, Box, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Scoreline from "./scoreline";
import HomeDrawAway from "./HomeDrawAway";
import TopPrediction from "./topprediction";
import RoundInfo from "./roundInfo";
import ScorePredictions from "./ScorePredictions";
import { Link } from "react-router-dom";

const MatchCard = ({ match, isPremium }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isFutureMatch = match.state.id !== 5;

  return (
    <Box
      width="100%"
      marginTop="15px"
      borderRadius="10px"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
      border="1px solid rgba(255, 255, 255, 0.2)"
      bgcolor={colors.primary[400]}
      overflow="hidden"
    >
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        padding={{
          xs: 1,
          md: 2,
        }}
      >
        {/* ROUND INFORMATION */}
        <RoundInfo round={match.round} />

        {/* SCORELINE */}
        <Scoreline
          match={match.main_info}
          scores={match.scores}
          state_id={match.state.id}
        />

        <Stack width="100%" alignItems="center" mt={1}>
          <Link
            to={`/fixture/${match.main_info.id}`}
            style={{
              textDecoration: "none",
              color: "inherit",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              width="100%"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              display="flex"
              gap={1}
              sx={{
                padding: "5px 10px",
                ":hover": {
                  color: "secondary.main",
                },
                transition: "color 0.3s",
              }}
            >
              <Typography
                variant="h6"
                component="span"
                width="50%"
                display="flex"
                flexGrow={1}
                flexShrink={1}
                flexBasis={0}
                justifyContent="flex-end"
              >
                {match.main_info.home_team.name}{" "}
              </Typography>
              <Typography variant="h6" component="span">
                vs{" "}
              </Typography>
              <Typography
                variant="h6"
                component="span"
                width="50%"
                display="flex"
                flexGrow={1}
                flexShrink={1}
                flexBasis={0}
              >
                {match.main_info.away_team.name}
              </Typography>
            </Typography>
          </Link>
        </Stack>
      </Stack>

      <Box
        sx={{
          filter: isFutureMatch && !isPremium ? "blur(10px)" : "none"
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          width="100%"
          minHeight="150px"
          padding={{
            xs: 1,
            md: 2,
          }}
        >
          {/* Home Draw Away */}
          <HomeDrawAway
            HDA={match.home_draw_away}
            state_id={match.state.id}
            scores={match.scores}
          />

          {/* TOP PREDICTIONS */}
          <TopPrediction matches={match.top_three_predictions} />

          {/* SCORE PREDICTIONS */}
          <ScorePredictions scores={match.top_score_predictions} />
        </Stack>
      </Box>
    </Box>
  );
};

export default MatchCard;
