import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { styled } from '@mui/system';

const ResponsiveBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '15px',
  backgroundColor: tokens(theme.palette.mode).primary[400],
  borderRadius: '10px',
  padding: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: '5px',
  },
}));

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: tokens(theme.palette.mode).primary[400],
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    '& .MuiTableCell-root': {
      padding: '4px',
      fontSize: '14px',
    },
  },
}));

const EuroGroups = ({ groups, isPremium }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Function to get color based on probability
  const getColor = (probability) => {
    if (probability >= 0.75) return '#00FF00'; // Green
    if (probability >= 0.50) return '#7FFF00'; // Light Green
    if (probability >= 0.25) return '#FFBF00'; // Orange
    return colors.redAccent[400]; // Red
  };

  if (!groups || groups.length === 0) return null;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: "100%" }}>
      {groups.map((group, groupIndex) => (
        <ResponsiveBox key={groupIndex}>
          <ResponsiveTableContainer component={Box}>
            <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>Group {group.name}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", fontSize: "20px" }}>Team</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "20px" }}>1</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "20px" }}>2</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "20px" }}>3</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "20px" }}>4</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ filter: isPremium ? "none" : "blur(10px)" }}>
                {group.teams.map((team, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ borderBottom: 'none', fontWeight: "bold", fontSize: "18px", filter: "none" }}>
                      {team.name}
                    </TableCell>
                    {team.probability.map((prob, i) => (
                      <TableCell
                        key={i}
                        align="center"
                        sx={{
                          borderBottom: 'none',
                          fontWeight: "bold",
                          backgroundColor: getColor(prob),
                          color: theme.palette.getContrastText(getColor(prob)),
                          fontSize: { xs: "12px", sm: "14px", md: "18px", lg: "20px" },
                        }}
                      >
                        {(prob * 100).toFixed(2)}%
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ResponsiveTableContainer>
        </ResponsiveBox>
      ))}
    </Box>
  );
};

export default EuroGroups;