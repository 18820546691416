import React from "react";
import FixtureInfo from "./FixtureInfo";

const Fixture = ({ fixture, fixtureIndex, user, colors }) => {
  console.log("🚀 ~ file: Fixture.jsx:5 ~ Fixture ~ fixture:", fixture);
  try {
    return (
      <FixtureInfo
        fixture={fixture.main_info}
        tips={fixture.top_prediction}
        status={fixture.state}
        scores={fixture.scores}
        periods={fixture.periods}
        fixtureIndex={fixtureIndex}
        user={user}
        colors={colors}
        scorelinePrediction={fixture.top_score_prediction}
      />
    );
  } catch (error) {
    console.error("Error rendering fixture:", error);
    return null;
  }
};

export default Fixture;
