import React, { useState } from 'react';
import { Helmet } from "react-helmet"; // import Helmet
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Container, Typography, TextField, Button, Link, Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { signInWithEmailAndPassword } from "firebase/auth";
import { CustomSignIn } from './SignIn.styles';

function SignIn() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);

        navigate('/');
    } catch (error) {
        // Handle Errors here.
        console.error(error);
        setError(error.message);
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (err) {
      setError(handleErrors(err.code)); // Use the new handleErrors function
    }
  };

  const handleErrors = (errorCode) => { // Map Firebase error codes to user-friendly messages
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'Invalid email format.';
      case 'auth/user-disabled':
        return 'This account has been disabled.';
      case 'auth/user-not-found':
        return 'No account found with this email.';
      case 'auth/wrong-password':
        return 'Wrong password.';
      default:
        return 'Sign-in error. Please try again.';
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Helmet> {/* Use Helmet to add a title and meta description */}
        <title>Sign In - Eagle Odds</title>
        <meta name="description" content="Sign in to your Eagle Odds account to access the betting tips." />
      </Helmet>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h3" fontWeight='bold'>
          Sign in to Eagle Odds
        </Typography>

        {/* Google Sign In */}
        <CustomSignIn 
          onClick={signInWithGoogle} 
          variant="contained" 
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#FFF',  // Google brand color
            color: '#000',  // Text color
            borderRadius: '2px',  // Google's buttons are usually more square
            textTransform: 'none',  // Google's buttons don't have capitalized letters
            fontFamily: 'Roboto, sans-serif',  // Google's brand font
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',  // Google-style box-shadow
            padding: '8px 24px',  // Padding as per Google guidelines
            display: 'flex',
            alignItems: 'center'
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/google_logo.png`} alt='Google Logo' style={{ height: '18px', marginRight: '8px' }}/>}  // Adjust the logo size and margin
        >
          Sign in with Google
        </CustomSignIn>
        
        {/* Facebook Sign In */}
        {/* <CustomSignIn 
          //onClick={signInWithFacebook} 
          variant="contained" 
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#3B5998',  // Facebook brand color
            color: colors.grey[100],  // Text color
            borderRadius: '2px',  
            textTransform: 'none',  
            fontFamily: 'Roboto, sans-serif',  
            fontSize: '14px',
            fontWeight: '500',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)' 
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/facebook_logo.png`} alt='Facebook Logo' style={{ maxHeight: '2.5vh' }}/>}  // Path to the Facebook logo image
        >
          Sign in with Facebook
        </CustomSignIn> */}

        {/* Apple Sign In */}
        {/* <CustomSignIn 
          //onClick={signInWithApple} 
          variant="contained"
          sx={{ 
            mt: 2, 
            mb: 1, 
            backgroundColor: '#000000',  // Apple brand color
            borderRadius: '2px',  
            textTransform: 'none',  
            fontFamily: 'Roboto, sans-serif',  
            fontSize: '14px',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)'
          }}
          startIcon={<img src={`${process.env.PUBLIC_URL}/apple_logo_white.png`} alt='Apple Logo' style={{ maxHeight: '3.5vh' }}/>}  // Path to the Apple logo image
        >
          Sign in with Apple
        </CustomSignIn> */}

        <Typography component="h1" variant="h5" fontWeight='bold' m={1}>
          Or sign up with your email address
        </Typography>

        <Box component="form" onSubmit={signIn} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography variant="body2" color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Box textAlign="center">
            <Link href="/signup" variant="body2" color={ colors.greenAccent[500] }>
              {"Don't have an account? Sign Up"}
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
