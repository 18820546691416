import { Typography, Paper, useTheme, Stack } from "@mui/material";
import { tokens } from "../../../theme";

const formatMatchName = (name) => {
  const totalSpaces = 20;
  const nameLength = name.length;
  const spacesToAdd = totalSpaces - nameLength;
  if (spacesToAdd > 0) {
    const padding = "\u00A0".repeat(spacesToAdd); // Half spaces on both sides if possible
    return `${name}${padding}`;
  }
  return name; // Return original name if it's longer than 7 characters
};

const TopPredictions = ({ matches }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!matches || matches.length === 0) {
    return null;
  }

  return (
    <Stack
      width="100%"
      margin={{
        xs: "10px 0",
        md: "20px 0",
      }}
      direction="column"
      alignItems="center"
      gap={1}
    >
      {matches.map((match, index) => {
        return (
          <Stack
            key={index}
            width="100%"
            maxWidth="400px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            padding={1}
            bgcolor={colors.primary[400]}
            borderRadius="10px"
            border="1px solid rgba(255, 255, 255, 0.2)"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              flexGrow={1}
              flexShrink={1}
              flexBasis={0}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                color={colors.text}
              >
                {formatMatchName(match.name)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              flexGrow={1}
              flexShrink={1}
              flexBasis={0}
              justifyContent="space-between"
            >
              <Paper
                variant="outlined"
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  padding: "1px 4px",
                  width: "fit-content",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} color={colors.text}>
                  {match.odds.toFixed(2)}
                </Typography>
              </Paper>
              <Paper
                variant="outlined"
                sx={{
                  backgroundColor: colors.greenAccent[700],
                  padding: "1px 4px",
                  width: "fit-content",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} color={colors.text}>
                  {match.probability.toFixed(0)}%
                </Typography>
              </Paper>
              <Paper
                variant="outlined"
                sx={{
                  backgroundColor: colors.greenAccent[700],
                  padding: "1px 4px",
                  width: "fit-content",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} color={colors.text}>
                  {((match.expected_value - 1) * 100).toFixed(1)}%
                </Typography>
              </Paper>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TopPredictions;
