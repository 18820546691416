import { Stack, useTheme, Typography, Checkbox } from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";

const Comments = ({ comments }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showOnlyImportant, setShowOnlyImportant] = useState(false);
  const [filteredComments, setFilteredComments] = useState(comments);

  const handleShowOnlyImportant = () => {
    setShowOnlyImportant(!showOnlyImportant);
    if (showOnlyImportant) {
      setFilteredComments(comments);
    } else {
      setFilteredComments(comments.filter((comment) => comment.is_important));
    }
  };

  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      padding={{ xs: 1, md: 2 }}
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        width="100%"
        justifyContent="flex-start"
      >
        <Typography variant="h6">Show only important events</Typography>
        <Checkbox
          checked={showOnlyImportant}
          onChange={handleShowOnlyImportant}
          color="secondary"
        />
      </Stack>
      <Stack direction="column" width="100%" alignItems="center" gap={2}>
        {filteredComments.map((comment) => (
          <Stack
            key={comment.id}
            direction="column"
            alignItems="flex-start"
            width="100%"
            borderRadius="10px"
            padding={{
              xs: 1,
              md: 2,
            }}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
            border="1px solid rgba(255, 255, 255, 0.2)"
          >
            <Stack direction="column" alignItems="flex-start">
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="body2"
                  width="20px"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {comment.minute ? `${comment.minute}'` : ""}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  borderLeft={`2px solid ${colors.blueAccent}`}
                  paddingLeft="10px"
                  gap={2}
                >
                  <Typography variant="body1">{comment.comment}</Typography>
                  {comment.is_goal && (
                    <img
                      src="/event_icons/goal.png"
                      alt="Goal"
                      height="20px"
                      width="20px"
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Comments;
