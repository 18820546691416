import React from "react";
import { AccordionSummary, Typography, Box } from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";

const TipsAccordionSummary = ({ league, theme }) => {
  return (
    <AccordionSummary
      expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
        height: "3.5rem",
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(90deg)",
        },
        "& .MuiAccordionSummary-content": {
          marginLeft: theme.spacing(1),
        },
      }}
    >
      <Box
        component="img"
        src={league.icon}
        alt={league.name}
        sx={{
          height: "2.75vh",
          mr: 1,
        }}
      />
      <Typography fontWeight="bold">{league.name} ({league.fixtures.length})</Typography>
    </AccordionSummary>
  )
}

export default TipsAccordionSummary;