import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import PrivacySummary from './privacySummary';
import PrivacyDetailed from './privacyDetailed';

const PrivacyPolicy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1rem',
          maxWidth: {
            xs: '100%',
            sm: '80%',
            md: '60%',
            lg: '50%',
            xl: '40%',
          },
          margin: '0 auto',
        }}
      >
        <Stack spacing={2}>

          {/* Title */}
          <Typography 
            sx={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              padding: '1rem',
            }}
          >
            Privacy Policy for <span style={{ color: colors.greenAccent[500] }}>eagleodds.ai</span>
          </Typography>

          {/* Introduction */}
          <Typography 
            sx={{
              fontSize: '1rem',
              padding: '1rem',
            }}
          >
            At eagleodds.ai, accessible from eagleodds.ai, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by eagleodds.ai and how we use it.
          </Typography>
          
          {/* Summary Section */}
          <PrivacySummary colors={colors} />

          {/* Detailed Version */}
          <PrivacyDetailed colors={colors} />

        </Stack>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
