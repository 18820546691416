import React from "react";
import {
  Grid,
  Typography,
  useTheme,
  Link,
  Container,
  Stack,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { tokens } from "../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentYear = new Date().getFullYear();

  const footerStyle = {
    padding: "20px 0",
    backgroundColor: colors.primary[400],
  };

  return (
    <footer style={footerStyle}>
      <Container maxWidth="lg">

        {/* 18 + disclaimer */}
        <Typography
          sx={{
            color: colors.redAccent[400],
            fontSize: "0.8rem",
            textAlign: "center",
            marginBottom: "0.5rem",
          }}
        >
          This site is not intended for use by anyone under the age of 18
          years old.
        </Typography>
        
        {/* Footer content */}
        <Grid container spacing={3}>
          <Grid item xs={6} style={{ textAlign: "right" }}>

            {/* Social media icons */}
            <Stack direction="row" spacing={1}>
              <Link
                href="https://twitter.com/eagleodds"
                target="_blank"
                rel="noopener"
              >
                <TwitterIcon style={{ color: colors.grey[200] }} />
              </Link>
              <Link
                href="https://instagram.com/eagleodds.ai"
                target="_blank"
                rel="noopener"
              >
                <InstagramIcon style={{ color: colors.grey[200] }} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/97937772"
                target="_blank"
                rel="noopener"
              >
                <LinkedInIcon style={{ color: colors.grey[200] }} />
              </Link>
              <Link href="https://facebook.com" target="_blank" rel="noopener">
                <FacebookIcon style={{ color: colors.grey[200] }} />
              </Link>
            </Stack>

            {/* Terms of service, privacy policy and subscription terms */}
            <Typography variant="body2" color={colors.grey[200]} align="left">
              <Link
                href="/terms"
                target="_blank"
                rel="noopener"
                style={{ color: colors.grey[200] }}
              >
                Terms of Service
              </Link>{" "}
              |
              <Link
                href="/privacy"
                target="_blank"
                rel="noopener"
                style={{ color: colors.grey[200] }}
              >
                {" "}
                Privacy Policy
              </Link>
              <Link
                href="/subscriptionterms"
                target="_blank"
                rel="noopener"
                style={{ color: colors.grey[200] }}
              >
                {" "}
                Subscription Terms
              </Link>
            </Typography>
          </Grid>

          {/* Contact info */}
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body2" color={colors.grey[200]}>
              <a
                href="mailto:support@eagleodds.ai"
                style={{ color: colors.grey[200] }}
              >
                support@eagleodds.ai
              </a>
            </Typography>
            <Typography variant="body2" color={colors.grey[200]}>
              <Link
                href="https://hjelpelinjen.no"
                target="_blank"
                rel="noopener"
                style={{ color: colors.grey[200] }}
              >
                Hjelpelinjen
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="body1" color={colors.grey[200]}>
              © {currentYear} Copyright Eagle Solutions AS
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
