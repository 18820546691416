import React from "react";
import { Accordion, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import TipsAccordionSummary from "./TipsAccordionSummary";
import TipsAccordionDetails from "./TipsAccordionDetails";

const TipsAccordion = ({ blurred, league, user, defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        "&:before": {
          display: "none",
        },

        background: "#1F2A40",
        color: "#fff",
        borderRadius: "10px !important",
        marginBottom: {
          xs: "0.2rem !important",
          sm: "0.2rem !important",
          md: "0.2rem !important",
          lg: "0.2rem !important",
        },

        "& .Mui-expanded": {
          marginY: "0",
          minHeight: "auto",
        },
      }}
    >
      <TipsAccordionSummary
        league={league}
        theme={theme}
      />
      <TipsAccordionDetails
        blurred={blurred}
        league={league}
        user={user}
        theme={theme}
        colors={colors}
      />  
    </Accordion>
  );
}

export default TipsAccordion;
