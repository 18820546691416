import { useEffect, useState } from "react";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import EuroHeader from "./header/Header";
import Groups from "./group/Groups";
import { fetchGroups, fetchFixtures, fetchProbabilites } from "./fetchData";
import Matches from "./matchCard/matches";

const Euro24 = ({ isPremium, user }) => {
  const [data, setData] = useState([]);
  const [probabilities, setProbabilities] = useState({ teams: [] });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchFixtures()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching fixtures:", error);
        // Handle the error here, e.g. show an error message to the user
      });
  }, []);

  useEffect(() => {
    fetchProbabilites()
      .then((data) => {
        setProbabilities(data);
      })
      .catch((error) => {
        console.error("Error fetching probabilities:", error);
        // Handle the error here, e.g. show an error message to the user
      });
  }, []);

  useEffect(() => {
    fetchGroups()
      .then((data) => {
        setGroups(data);
      })
      .catch((error) => {
        console.error("Error fetching groups:", error);
        // Handle the error here, e.g. show an error message to the user
      });
  }, []);

  // "euroHeader" | "groups" | "matches"
  const [currentTab, setCurrentTab] = useState("euroHeader");

  return (
    <Stack width="100%" direction="column" alignItems="center">
      <Stack
        direction="column"
        justifyContent="center"
        width={{ xs: "100%", md: "80%", lg: "60%" }}
        p={{
          xs: 1,
          md: 4,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          <img src="/euro24.png" alt="logo" height="50px" />
          <Typography
            component="h1"
            fontSize="24px"
            fontWeight="bold"
            textAlign="center"
          >
            Euro 2024
          </Typography>
        </Stack>
        <Tabs
          centered
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          textColor="secondary"
        >
          <Tab label="Predictions" value="euroHeader" />
          <Tab label="Groups" value="groups" />
          <Tab label="Matches" value="matches" />
        </Tabs>

        {/* Header */}
        {currentTab === "euroHeader" && (
          <EuroHeader topTeamsData={probabilities.teams} isPremium={isPremium} />
        )}

        {/* Groups */}
        {currentTab === "groups" && <Groups groups={groups} isPremium={isPremium} />}

        {/* Match Cards */}
        {currentTab === "matches" && <Matches matches={data} isPremium={isPremium} />}
      </Stack>
    </Stack>
  );
};

export default Euro24;
