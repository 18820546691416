import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const SubscriptionTerms = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        width: { xs: '95%', sm: '80%', md: '60%', lg: '50%' },
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1rem',
        background: colors.primary[400],
        padding: '2rem',
        marginTop: '2rem',
        marginBottom: '2rem',
      }}
    >
      <Typography 
        sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: colors.greenAccent[500],
          mb: '1rem',
        }}
      >
        General Subscription Terms for eagleodds.ai
      </Typography>

      <Typography paragraph>
        <strong>1. Introduction:</strong> These terms apply to all subscriptions and services provided by eagleodds.ai, operated by Eagle Solutions AS. By subscribing, you accept these terms.
      </Typography>

      <Typography paragraph>
        <strong>2. Parties:</strong> Seller is Eagle Solutions AS, operating eagleodds.ai. Email: support@eagleodds.ai. Buyer is the individual or entity registered as a subscriber.
      </Typography>

      <Typography paragraph>
        <strong>3. Subscription:</strong> Your order is binding once registered. We are bound by your order if it matches our offer in our channels or marketing materials.
      </Typography>

      <Typography paragraph>
        <strong>4. Prices and Payment:</strong> All costs will be clear at the time of order, including all associated expenses. Payment is processed through available solutions on our website or by invoice.
      </Typography>

      <Typography paragraph>
        <strong>5. Delivery:</strong> Digital services are provided immediately after purchase completion unless stated otherwise.
      </Typography>

      <Typography paragraph>
        <strong>6. Right of Use:</strong> You are granted the right to use eagleodds.ai services as per these terms. Resale or redistribution of access is prohibited.
      </Typography>

      <Typography paragraph>
        <strong>7. Personal Data:</strong> Eagle Solutions AS processes data in accordance with applicable laws, using your information solely to deliver and improve services.
      </Typography>

      <Typography paragraph>
        <strong>8. Dispute Resolution:</strong> Disputes should be resolved amicably, directed to us at the earliest. If unresolved, matters may be referred to the Consumer Council or the ordinary courts, under Norwegian law.
      </Typography>

      <Typography paragraph>
        <strong>9. Limitation of Liability:</strong> We disclaim liability for indirect losses. Our liability is limited to direct losses that were foreseeable and is capped at the total service fee for the last year.
      </Typography>

      <Typography paragraph>
        <strong>10. Force Majeure:</strong> Obligations are suspended under uncontrollable circumstances beyond our control, such as natural disasters or public restrictions.
      </Typography>

      <Typography paragraph>
        <strong>11. Breach of Terms:</strong> Non-payment or failure to comply may lead to service suspension or termination. Immediate written complaint is required for suspected breaches.
      </Typography>

      <Typography paragraph>
        <strong>12. Miscellaneous:</strong> We reserve the right to correct any typographical or obvious errors. For clarifications, contact us at support@eagleodds.ai.
      </Typography>
    </Box>
  );
}

export default SubscriptionTerms;
