import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { auth } from "../../Firebase";
import { signOut } from "firebase/auth";

const NavBar = () => {
  // State and context
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const [selected, setSelected] = React.useState("Dashboard");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  // Event Handlers
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleUserMenuClick = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorElUserMenu(null);
  };
  const handleSignOut = async () => {
    handleUserMenuClose();
    try {
      await signOut(auth);
      navigate("/"); // redirect to landing page
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
  const goToSettings = () => {
    handleUserMenuClose();
    navigate("/personalinfo");
  };

  // Menu Items
  const menuItems = [
    { title: "Tips", to: "/tips", icon: <AutoGraphOutlinedIcon /> },
    {
      title: "Subscription",
      to: "/subscription",
      icon: <ReceiptLongOutlinedIcon />,
    },
    { title: "About", to: "/about", icon: <InfoOutlinedIcon /> },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        color: colors.text,
        boxShadow: "none",
        "& .MuiToolbar-root": {
          backgroundColor: colors.primary[400],
        },
      }}
    >
      <Toolbar>
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Stack
            component={Link}
            to="/"
            direction="row"
            alignItems="center"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {/* <Link
          to="/"
          style={{ textDecoration: "none", color: "inherit", display: "block" }}
        > */}
            {/* Logo */}
            <img src="/logo_red.png" alt="logo" height="50px" />
            {/* </Link> */}

            {/* Title  */}
            <Typography
              sx={{
                flexGrow: 1,
                color: colors.greenAccent[500],
                fontWeight: "bold",
                fontSize: { xs: "1.0rem", md: "1.5rem" },
              }}
            >
              eagleodds.ai
            </Typography>
          </Stack>
        </Stack>

        {/* Navigation Buttons for desktop */}
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            component={Link}
            to="/euro24"
            onClick={() => setSelected("EURO 2024")}
            color={selected === "EURO 2024" ? "secondary" : "inherit"}
            sx={{ marginRight: "10px" }}
          >
            <img src="/euro24.png" alt="logo" height="25px" />
            <Typography sx={{ marginLeft: "10px" }}>EURO 2024</Typography>
          </Button>
          {menuItems.map((item) => (
            <Button
              key={item.title}
              component={Link}
              to={item.to}
              onClick={() => setSelected(item.title)}
              color={selected === item.title ? "secondary" : "inherit"}
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px", // Optional spacing between buttons
              }}
            >
              {item.icon} {/* Render the icon */}
              <Typography sx={{ marginLeft: "10px" }}>{item.title}</Typography>
            </Button>
          ))}
        </Box>

        {/* EURO 2024 BUTTON */}
        <Box
          sx={{
            padding: "0 10px",
            backgroundColor: colors.primary[400],
            borderRadius: "5px",
            display: { xs: "flex", md: "none" },
          }}
        >
          <Link
            to="/euro24"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            <Stack direction="row" alignItems="center">
              <img src="/euro24.png" alt="logo" height="25px" />
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: colors.greenAccent[500],
                }}
              >
                EURO 2024
              </Typography>
            </Stack>
          </Link>
        </Box>

        {/* SEE TIPS BUTTON */}
        <Box
          sx={{
            padding: "0 10px",
            backgroundColor: colors.primary[400],
            borderRadius: "5px",
            display: { xs: "none", sm: "flex", md: "none" },
          }}
        >
          <Link
            to="/tips"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "block",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: colors.greenAccent[500],
              }}
            >
              SEE TIPS
            </Typography>
          </Link>
        </Box>

        {/* Navigation Menu Icon for mobile */}
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
          sx={{
            display: { xs: "flex", md: "none" },
            marginLeft: "auto",
          }}
        >
          <MenuIcon />
        </IconButton>

        {/* Mobile Navigation Menu */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            // Styling for the Menu container
            "& .MuiPaper-root": {
              backgroundColor: colors.primary[400],
            },
          }}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.title}
              component={Link}
              to={item.to}
              onClick={handleCloseNavMenu}
              sx={{
                // Styling for individual MenuItems
                padding: "10px",
                "&:hover": {
                  backgroundColor: "secondary.light",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {item.icon} {/* Render the icon */}
                <Typography textAlign="center" sx={{ marginLeft: "10px" }}>
                  {item.title}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>

        {/* User Actions Icons */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleUserMenuClick}>
            <PersonOutlinedIcon />
          </IconButton>

          {/* User Menu */}
          <Menu
            anchorEl={anchorElUserMenu}
            open={Boolean(anchorElUserMenu)}
            onClose={handleUserMenuClose}
            sx={{
              // Styling for the Menu container
              "& .MuiPaper-root": {
                backgroundColor: colors.primary[400],
              },
            }}
          >
            <MenuItem onClick={goToSettings}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SettingsOutlinedIcon />
                <Typography sx={{ marginLeft: "10px" }}>Settings</Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={colorMode.toggleColorMode}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {theme.palette.mode === "light" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
                <Typography sx={{ marginLeft: "10px" }}>
                  {theme.palette.mode === "light" ? "Dark Mode" : "Light Mode"}
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleSignOut}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LogoutOutlinedIcon />
                <Typography sx={{ marginLeft: "10px" }}>Sign Out</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
