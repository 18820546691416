import { Typography, Paper, useTheme, Stack } from "@mui/material";
import { tokens } from "../../../theme";

const formatName = (name) => {
  const totalSpaces = 20;
  const nameLength = name.length;
  const spacesToAdd = totalSpaces - nameLength;
  if (spacesToAdd > 0) {
    const padding = "\u00A0".repeat(spacesToAdd); // Add non-breaking spaces
    return `${name}${padding}`;
  }
  return name; // Return original name if it's longer than the totalSpaces
};

const Predictions = ({ predictions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!predictions || predictions.length === 0) {
    return null;
  }

  return (
    <Stack
      width="100%"
      margin={{
        xs: "10px 0",
        md: "20px 0",
      }}
      direction="column"
      alignItems="center"
      gap={2}
    >
      {predictions.map((prediction, index) => (
        <Stack
          key={index}
          width="100%"
          maxWidth="400px"
          direction="column"
          gap={2}
          padding={2}
          bgcolor={colors.primary[400]}
          borderRadius="10px"
          border="1px solid rgba(255, 255, 255, 0.2)"
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}
            color={colors.text}
          >
            {formatName(prediction.name)}
          </Typography>
          <Stack direction="column" gap={1}>
            {prediction.outcomes.map((outcome, outcomeIndex) => (
              <Stack
                key={outcomeIndex}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                padding={1}
                bgcolor={colors.primary[500]}
                borderRadius="10px"
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "16px", flexGrow: 1 }}
                  color={colors.text}
                >
                  {formatName(outcome.name)}
                </Typography>
                <Stack direction="row" gap={1}>
                  <Paper
                    variant="outlined"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      padding: "1px 4px",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      color={colors.text}
                    >
                      {outcome.odds.toFixed(2)}
                    </Typography>
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      padding: "1px 4px",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      color={colors.text}
                    >
                      {outcome.probability.toFixed(0)}%
                    </Typography>
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      padding: "1px 4px",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      color={colors.text}
                    >
                      {((outcome.expected_value - 1) * 100).toFixed(1)}%
                    </Typography>
                  </Paper>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default Predictions;
