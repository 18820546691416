import { Typography, Paper, Tooltip, Stack } from "@mui/material";
import NAComponent from "./NAComponent";
import { TeamLogo } from "../utils/Tips.styles";

export const renderBestTip = (data, colors) => {
  // Check if data is undefined or null
  if (!data || Object.keys(data).length === 0) {
    return <NAComponent />;
  }
  if (data.odds === 0) {
    return <NAComponent />;
  } else {
    return (
      <Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {data.correct !== null &&
            (data.correct ? (
              <TeamLogo src="/icons/correct.svg" alt="Correct" />
            ) : (
              <TeamLogo src="/icons/incorrect.svg" alt="Incorrect" />
            ))}
          <Typography
            variant="h4"
            fontWeight="bold"
            fontSize="1.5vh"
            sx={{ color: colors.blueAccent[300] }}
          >
            {data.name.toUpperCase()}
          </Typography>
          <Stack direction="row" spacing={0.2} justifyContent="center">
            <Paper
              elevation={3}
              sx={{
                marginRight: "5px",
                backgroundColor: colors.blueAccent[700],
              }}
            >
              <Tooltip
                title={`The odd is ${data.odds.toFixed(2)}`}
                placement="top"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize={{ xs: "0.75rem", md: "1rem" }}
                  sx={{ padding: "1px 4px" }}
                >
                  {data.odds.toFixed(2)}
                </Typography>
              </Tooltip>
            </Paper>
            <Paper elevation={3} sx={{ backgroundColor: "green" }}>
              <Tooltip
                title={`The probability is ${data.probability.toFixed(0)}%`}
                placement="top"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontSize={{ xs: "0.75rem", md: "1rem" }}
                  sx={{ padding: "1px 4px" }}
                >
                  {data.probability.toFixed(0)}%
                </Typography>
              </Tooltip>
            </Paper>
          </Stack>
        </Stack>
      </Stack>
    );
  }
};
