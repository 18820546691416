import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { createCheckoutSession } from '../../stripe/createCheckoutSession.ts';
import Loader from '../../components/Loader.jsx';

const UpgradeSubscriptionButton = ({ uid, premiumRole }) => {
  const [loading, setLoading] = useState(false);

  const handleUpgrade = async () => {
    setLoading(true);  // Start loading before the checkout session starts
    try {
      if (!uid) {
        // Redirect to signup page if uid is undefined
        window.location.href = '/signup';
        return;
      }
      await createCheckoutSession(uid, premiumRole);  // Wait for the checkout session to complete
    } catch (error) {
      console.error('Failed to upgrade subscription:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={handleUpgrade}
        disabled={loading}  // Disable the button when loading
        startIcon={loading ? <Loader /> : null}  // Use the custom Loader as the start icon when loading
      >
        Upgrade Subscription
      </Button>
    </Box>
  );
};

export default UpgradeSubscriptionButton;
