const BASE_URL = "https://eagleodds-api-p4nwj3fy4q-lz.a.run.app/api/v1/fixture/";
// const BASE_URL = "http://localhost:8000/api/v1/fixture/";

async function fetchFixture(fixture_id) {
  try {
    const response = await fetch(`${BASE_URL}?fixture_id=${fixture_id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch fixture data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export { fetchFixture };
