import React from 'react';
import { Box } from '@mui/material';
import RenderOdds from '../../fixture/renderodds/RenderOdds';

const OddsAndTips = ({ oddsData }) => {
  return (
    <Box>
      <RenderOdds oddsData={oddsData} w={100} />
    </Box>
  );
}

export default OddsAndTips;
