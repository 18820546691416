import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const ScorePredictions = ({ scores }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!scores) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        margin: '10px 0 10px 0',
      }}
    >
      <Stack direction="row" spacing={4}>
        {scores.map((score, index) => (
          <Box key={index}>
            <Stack direction="column" spacing={0}>
              <Paper elevation={3} sx={{ backgroundColor: colors.blueAccent[700] }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '12px', padding: "1px 4px" }} color={colors.text}>
                  {score.home_team_score} - {score.away_team_score}
                </Typography>
              </Paper>
              <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }} color={colors.text}>
                {(score.probability).toFixed(1)}%
              </Typography>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default ScorePredictions;
