import { Box } from "@mui/material"
import CountdownTimer from "../../../components/CountdownTimer"


const Time = ({ endTime }) => {

  return (
    <Box
      sx={{
        margin: "auto",
        width: "40%",
        mb: "-15px",
      }}
    >
      <CountdownTimer endTime={endTime} />
    </Box>
  )
}

export default Time;