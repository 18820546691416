const BASE_URL = "https://eagleodds-api-p4nwj3fy4q-lz.a.run.app/api/v1/euro";

async function fetchGroups() {
  try {
    const response = await fetch(BASE_URL + "/groups");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

async function fetchFixtures() {
  try {
    const response = await fetch(BASE_URL + "/fixtures");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

async function fetchProbabilites() {
  try {
    const response = await fetch(BASE_URL + "/winner_probabilities");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export { fetchGroups, fetchFixtures, fetchProbabilites };