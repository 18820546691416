import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  // useTheme,
  Tab,
  Tabs,
  Stack,
} from "@mui/material";
// import { tokens } from "../../theme";
import { fetchFixture } from "./fetchFixture";
import MainInfo from "./maininfo";
import Statistics from "./Statistics";
import Comments from "./Comments";
import Lineups from "./Lineups";
import Predictions from "./Predictions";
// import Predictions from "./Predictions";
// import Statistics from "./Statistics";
// import Lineups from "./Lineups";
// import Comments from "./Comments";

const Fixture = () => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const { fixture_id } = useParams(); // Ensure fixture_id is captured correctly

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // "predictions" | "statistics"
  const [currentTab, setCurrentTab] = useState("predictions");

  useEffect(() => {
    const getFixtureData = async () => {
      try {
        const data = await fetchFixture(fixture_id);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (fixture_id) {
      // Ensure fixture_id is not undefined
      getFixtureData();
    }
  }, [fixture_id]);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexGrow={1}
        sx={{
          width: { xs: "90%", md: "70%", lg: "60%" },
          margin: "20px auto",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: { xs: "90%", md: "70%", lg: "60%" },
          margin: "20px auto",
        }}
      >
        <Typography variant="h6" color="error">
          Error fetching data: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack width="100%" direction="column" alignItems="center">
      <Stack
        direction="column"
        justifyContent="center"
        width={{ xs: "100%", md: "80%", lg: "60%" }}
        p={{
          xs: 1,
          md: 4,
        }}
      >
        {/* Go back */}
        {/* TODO */}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          {/* Main info */}
          <MainInfo
            mainInfo={data.main_info}
            starting_at={data.main_info.starting_at}
            periods={data.periods}
            state_id={data.state.id}
            scores={data.scores}
            home_draw_away={data.home_draw_away}
            HDA={data.home_draw_away}
          />
        </Stack>

        {/* Tabs */}
        <Tabs
          centered
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          textColor="secondary"
        >
          <Tab label="Predictions" value="predictions" />
          <Tab label="Statistics" value="statistics" />
          <Tab label="Lineups" value="lineups" />
          <Tab label="Comments" value="comments" />
        </Tabs>

        {/* Tab content */}
        {currentTab === "predictions" && <Predictions predictions={data.predictions} />}
        {currentTab === "statistics" && <Statistics statistics={data.statistics} />}
        {currentTab === "lineups" && (
          <Lineups lineups={data.lineup} main_info={data.main_info} />
        )}
        {currentTab === "comments" && <Comments comments={data.comments} />}
      </Stack>
    </Stack>
  );
};

export default Fixture;
