// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1ek5Kt7x7CqljJtQDkr1AWyInJ8Fbz54",
  authDomain: "betting-ai-668ab.firebaseapp.com",
  projectId: "betting-ai-668ab",
  storageBucket: "betting-ai-668ab.appspot.com",
  messagingSenderId: "477936300794",
  appId: "1:477936300794:web:06d67978f064ba7ec4cf43",
  measurementId: "G-LB5JVDZ9Q5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Get the Firebase Auth instance
const auth = getAuth(app);

setPersistence(auth, browserSessionPersistence)

export { auth };
