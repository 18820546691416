import { Box, Typography } from '@mui/material';

const Scoreline = ({ fixture }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Adjusted for spacing
        width: '100%',
        gap: '20px',
        marginTop: { xs: '0', sm: '5px', md: '10px', lg: '15px'},
        marginBottom: { xs: '10px', sm: '10px', md: '10px', lg: '15px'},
        paddingBottom: { xs: '10px', sm: '10px', md: '10px', lg: '15px'},
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      {/* Home team on the left */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexBasis: '40%', // Set a maximum basis
          justifyContent: 'flex-end',
          gap: '10px',
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          }
        }}
      >
        <Typography component="span" className="team-name" fontWeight="bold" fontSize={{ xs: "0.75rem", sm: "0.80rem", md: "1.2rem" }}>
          {fixture.home.name}
        </Typography>
        <img src={fixture.home.img_path} alt={fixture.home.name} width={30} />
      </Box>

      {/* Score in the center */}
      <Box
        sx={{
          textAlign: "center",
          flexBasis: '20%', // Center element basis
        }}
      >
        <Typography
          component="span"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
          }}
        >
          {fixture.scores.state_id === "1" 
            ? fixture.time
            : `${fixture.scores.home_score} - ${fixture.scores.away_score}`}
        </Typography>
      </Box>

      {/* Away team on the right */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexBasis: '40%', // Set a maximum basis
          justifyContent: 'flex-start',
          gap: '10px',
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          }
        }}
      >
        <img src={fixture.away.img_path} alt={fixture.away.name} width={30}/>
        <Typography component="span" className="team-name" fontWeight="bold" fontSize={{ xs: "0.75rem", sm: "0.80rem", md: "1.2rem" }}>
          {fixture.away.name}
        </Typography>
      </Box>
    </Box>
  )
}

export default Scoreline;