import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StatLineWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StatValue = styled(Box)(({ theme, highlight }) => ({
  minWidth: '30px',
  padding: '4px 8px',
  borderRadius: '50%',
  textAlign: 'center',
  backgroundColor: highlight ? 'teal' : 'transparent',
  color: highlight ? theme.palette.common.white : theme.palette.text.primary,
  fontWeight: 'bold',
}));

const StatName = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const StatLine = ({ name, home, away }) => {
  const isHomeHigher = home.value > away.value;
  const isAwayHigher = away.value > home.value;

  return (
    <StatLineWrapper direction="row">
      <StatValue highlight={isHomeHigher}>
        {home.value}
      </StatValue>
      <StatName variant="body1">{name}</StatName>
      <StatValue highlight={isAwayHigher}>
        {away.value}
      </StatValue>
    </StatLineWrapper>
  );
};

export default StatLine;
