import { db } from '../../../../Firebase';
import { collection, query, getDocs, orderBy, limit } from 'firebase/firestore';

const fetchTweets = async () => {
  try {
    const tweetsCollectionRef = collection(db, 'tweets');
    const q = query(tweetsCollectionRef, orderBy('createdAt', 'desc'), limit(3));
    const querySnapshot = await getDocs(q);
    const tweets = [];
    querySnapshot.forEach((doc) => {
      tweets.push({ id: doc.id, ...doc.data() });
    });
    return tweets;
  } catch (error) {
    console.error('Error fetching tweets from Firestore:', error);
    throw error;
  }
};

export default fetchTweets;
