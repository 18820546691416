import { Box, Typography } from "@mui/material";
import CountdownTimer from "../../../components/CountdownTimer";

const Time = ({ state_id, liveTime, starting_at }) => {

  return (
    <>
      {state_id === 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            width: "33%",
            textAlign: "center",
            mb: "-15px",
          }}
        >
          <CountdownTimer endTime={starting_at} />
        </Box>
      ) : (
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ width: "100%", textAlign: "center", mb: "-15px"}} 
        >
          {liveTime}
        </Typography>
      )}
    </>
  )
}

export default Time;