import { utcToZonedTime, format, zonedTimeToUtc } from "date-fns-tz";

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertToLocalTime = (starting_at) => {
  const timeZone = getTimeZone();
  const utcDate = zonedTimeToUtc(starting_at, "UTC");
  const localTime = utcToZonedTime(utcDate, timeZone);
  return format(localTime, "HH:mm", { timeZone });
};

export const findLiveTime = (periods) => {
  let matchTime = 0;
  if (periods.first_half.started && periods.first_half.ended) {
    const firstHalfDuration = (periods.first_half.ended - periods.first_half.started) / 1000 / 60;
    matchTime += firstHalfDuration;
  }
  if (periods.second_half.started && !periods.second_half.ended) {
    const now = new Date().getTime();
    const secondHalfDuration = (now - periods.second_half.started) / 1000 / 60;
    matchTime += secondHalfDuration;
  }

  return Math.floor(matchTime);
};


export const dateToStr = (date) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};
