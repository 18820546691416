import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { tokens } from '../../../theme';
import CTAButtons from './CTA';

const IntroSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        justifyContent: 'center',
        margin: 'auto',
        width: '95%',
        height: isLargeScreen ? '75vh' : "50vh",
        alignItems: isLargeScreen ? 'center' : 'start',
        backdropFilter: 'blur(2px)',
      }}
    >
      {/* Text Content */}
      <Box
        sx={{
          width: isLargeScreen ? '33%' : '100%',
          padding: isLargeScreen ? 0 : '16px',
        }}
      >
        {/* Main text */}
        <Typography
          sx={{
            fontSize: { xs: '2.5rem', sm: '3rem', md: '4rem', lg: '4rem'},
            fontWeight: 'bold',
            textAlign: 'left',
            lineHeight: { xs : '1.2', sm: '1.2', md: '1', lg: '1' },
            mb: { xs: 4, sm: 2, md: 0, lg: 0 }
          }}
        >
          <span style={{ color: colors.greenAccent[500] }}>eagleodds.ai</span> makes football your game, optimized!
        </Typography>

        {/* Subtext */}
        {isLargeScreen && (
          <Typography
            sx={{
              fontSize: {
                xs: '1rem'
              },
              textAlign: 'left',
            }}
          >
            We use machine learning to predict certain outcomes of <strong>hundreds of football</strong> matches every week.
            Comparing to what the bookmakers offer, we calculate the <strong>expected value</strong> of each bet and present you with the best ones.
          </Typography>
        )}
        
        {/* Call to action */}
        <CTAButtons />
      </Box>

      {/* Images for Large Screens */}
      {isLargeScreen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '2rem',
            width: '33%',
          }}
        >
          <img src="tips-mobile.jpeg" alt="Service on iPhone" style={{ height: '50vh', position: 'relative', right: '-20%' }} />
          <img src="tips-desktop.jpeg" alt="Service on Laptop" style={{ width: '100%', height: '100%', objectFit: 'contain', marginRight: '-20%' }} />
        </Box>
      )}
    </Box>
  )
}

export default IntroSection;