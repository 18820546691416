import HowItWorks from '../global/HowItWorks';

const PredictionGuide = () => {
  return (
    <>
      <HowItWorks />
    </>
  );
}

export default PredictionGuide;
